import React, { useEffect, useState } from "react";
import Twiny from "./abis/Twiny.json";
import Web3 from "web3";
import { getNFTs } from "./getNfts";
import { Card } from "react-bootstrap";
import "./nfts?.css";
import { useParams } from "react-router-dom";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnect } from "../WalletConnect/WalletConnect";
const NftDetail = () => {
  const { id } = useParams();
  const [web3, setWeb3] = useState(null);
  const [nfts, setNFTs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState(null);
  const [currentProvider, setCurrentProvider] = useState(null);
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const fetchNFTs = async (_WalletAddress) => {
    if (_WalletAddress) {
      setIsLoading(true);
      try {
        const nftContractAddress = "0x0fD999944ff789313839FF9923E96100d338dAe7"; // Address of the NFT contract
        //console.log(nftContractAddress);
        const nftTokens = await getNFTs(_WalletAddress, nftContractAddress);

        const nftstokenfilter = nftTokens.filter((item) => item.tokenId === id);
        console.log("selected nft is ", nftstokenfilter);

        // Set the NFTs in state
        setNFTs(nftstokenfilter[0]);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch NFTs:", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNFTs(walletAddress);
  }, [walletAddress]);

  function importNFTs() {
    let providers_ = Array.from(window.ethereum.providerMap);

    providers_.map((item) => {
      if (item[0] == "MetaMask") {
        console.log(item[1].request);
        item[1]
          .request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC721",
              options: {
                address: "0x0fD999944ff789313839FF9923E96100d338dAe7",
                symbol: "Twinny",
                tokenId: id + "",
              },
            },
          })
          .then((success) => {
            if (success) {
              console.log("FOO successfully added to wallet!");
            } else {
              throw new Error("Something went wrong.");
            }
          })
          .catch((e) => {
            console.log(e);
            // alert("Wallet does not support imprting NFTs");
          });
      }
    });
    console.log("providers are", providers_);
  }

  function NFTCard(nft) {
    return (
      <>
        {nft.rawMetadata?.attributes.map((attribute, index) => (
          <li className="nfts-text" key={index}>
            <span className="nfts-heading">{attribute.trait_type}: </span>
            {attribute?.value}
          </li>
        ))}
      </>
    );
  }
  console.log({ currentProvider });
  return (
    <>
      {!walletAddress ? (
        <div
          className="d-flex justify-content-center align-items-center p-5"
          style={{ height: "100vh" }}
        >
          <WalletConnect
            addressSetter={setWalletAddress}
            setCurrentProvider={setCurrentProvider}
          />
        </div>
      ) : (
        id && (
          <div className="container-fluid">
            <div>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center p-5">
                  <div className="spinner-border text-dark" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {walletAddress && !isLoading && nfts?.length === 0 ? (
                    <div
                      className="d-flex justify-content-center align-items-center p-5"
                      style={{ height: "100vh" }}
                    >
                      <div className="main-heading-nft">
                        No NFT Details Available.
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center p-3">
                      <div className="nft-detail-layout col-12 col-md-6 p-2 border">
                        <div className="d-flex justify-content-center main-heading-nft pb-3">
                          NFT Details
                        </div>
                        {/* <div className="d-flex justify-content-center pt-2 pb-2">
                        <button className="wallet-btn " onClick={disconnect}>
                          Disconnect Wallet
                        </button>
                      </div> */}

                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}
                        {/* --------------- */}

                        <div className="d-lg-flex pt-3 pe-2">
                          <div className="px-3 py-lg-0 py-3 col-lg-5 col-12">
                            <img
                              src={nfts?.rawMetadata?.image}
                              alt="watch"
                              className="w-100"
                              // style={{ maxWidth: "75%" }}
                            />
                          </div>

                          <div
                            className="p-3 col-lg-7 col-12"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <div className="details-page-main-heading-two pb-3">
                                  Name : {nfts?.rawMetadata?.name}
                                </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                <div className="details-page-main-heading-two pb-3">
                                  Price :{" "}
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[2]?.value
                                    : null}{" "}
                                  $
                                </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                <div className="details-page-main-heading-two pb-3">
                                  Token ID : #{nfts?.tokenId}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="details-page-main-heading-two pb-3">
                                  Description: #{nfts?.description}
                                </div>
                              </div>
                              <div>
                                <div className="col-lg-12">
                                  <div className="d-flex align-content-center">
                                    <div className="me-3">
                                      <a
                                        href={`${nfts?.rawMetadata?.attributes[0]?.value}`}
                                        target="_blank"
                                        download
                                        className="text-decoration-none transfer-btn p-1"
                                      >
                                        Download Pdf
                                      </a>
                                    </div>
                                    {currentProvider === "MetaMask" && (
                                      <div className=" ">
                                        <div>
                                          <div>
                                            <button
                                              onClick={importNFTs}
                                              className="text-decoration-none transfer-btn p-1"
                                              //   style={{
                                              //     background: "#4182eb",
                                              //     borderRadius: "3px",
                                              //     border: "none",
                                              //     fontSize: "12px",
                                              //     lineHeight: "18px",
                                              //     color: "#fff",
                                              //     fontWeight: "bold",
                                              //   }}
                                            >
                                              import NFT
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-lg-12 col-12 pt-3"
                            style={{ height: "fit-content" }}
                          >
                            <div
                              className={`details-container-heading-light ps-2`}
                            >
                              Case
                            </div>
                            <div
                              className="p-3 m-2"
                              style={{
                                background: "#fff",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Finishes
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[3]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Height
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[4]?.value
                                    : null}
                                  mm
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Strap
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[5]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Screwed-in lugs
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[6]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Diameter
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[7]?.value
                                    : null}
                                  mm
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Waterproofing
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[8]?.value
                                    : null}
                                  m
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Clasp
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[9]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Case back
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[10]?.value
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-12 col-12 pt-3"
                            style={{ height: "fit-content" }}
                          >
                            <div
                              className={`details-container-heading-light ps-2`}
                            >
                              Dial
                            </div>
                            <div
                              className="p-3 m-2"
                              style={{
                                background: "#fff",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Glass
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[11]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Center Dial
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[12]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Complication
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[13]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Exterior elevation
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[14]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Hands
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[15]?.value
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-lg-12 col-12 pt-3"
                            style={{ height: "fit-content" }}
                          >
                            <div
                              className={`details-container-heading-light ps-2`}
                            >
                              Crown
                            </div>
                            <div
                              className="p-3 m-2 row"
                              style={{
                                background: "#fff",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Info
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[16]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Material
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[17]?.value
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-12 col-12 pt-3 pt-lg-0"
                            style={{ height: "fit-content" }}
                          >
                            <div
                              className={`details-container-heading-light ps-2`}
                            >
                              Movement
                            </div>
                            <div
                              className="p-3 m-2 row"
                              style={{
                                background: "#fff",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Automatic
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[18]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Access
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[19]?.value
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-12 col-12 pt-3 pt-lg-0"
                            style={{ height: "fit-content" }}
                          >
                            <div
                              className={`details-container-heading-light ps-2`}
                            >
                              Guarantee
                            </div>
                            <div
                              className="p-3 m-2 row"
                              style={{
                                background: "#fff",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Purchase Date
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[20]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Purchase Time
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[21]?.value
                                    : null}
                                </p>
                              </div>
                              <div
                                style={{ border: "1px solid #E2E2E2" }}
                              ></div>
                              <div className="d-flex pt-2 pb-2">
                                <p className="details-page-heading col-5">
                                  Guarantee
                                </p>
                                <p className="details-page-text col-6">
                                  {nfts?.rawMetadata?.attributes
                                    ? nfts?.rawMetadata?.attributes[22]?.value
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default NftDetail;
