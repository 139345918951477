import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHighTransfers } from "../../../store/highTransferSlice";
import { STATUSES } from "../../../store/highTransferSlice";
import arrow from "../../../assets/images/arrow.svg";
import "./transfer-watches.css";
import { useNavigate } from "react-router-dom";
import { imageURL } from "../../../axios";

function TransferWatches() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { data: transfers, status } = useSelector(
    (state) => state.highTransferWatch
  );
  useEffect(() => {
    dispatch(fetchHighTransfers(token));
  }, []);

  if (status === STATUSES.LOADING) {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <div className="spinner-border text-dark" role="status">
          {/* <span className="visually-hidden">Loading...</span> */}
        </div>
      </div>
    );
  }

  if (status === STATUSES.ERROR) {
    return <div className="failed-text">Something went wrong!</div>;
  }

  const RenderItems = () => {
    return (
      transfers.transactions &&
      transfers.transactions.map((data) => (
        <div key={data._id}>
          <div className="d-flex justify-content-between align-items-center flex-row p-3 me-2">
            <div className="d-flex align-items-center col-10">
              <div className="d-flex justify-content-center col-3">
                <img
                  src={ data.image}
                  alt="icon"
                  style={{ maxHeight: "50px", maxWidth: "60px" }}
                />
              </div>
              <div className="transfer-watch-text col-9">{data.watchName}</div>
            </div>

            <div
              style={{
                background: "#4182EB",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                padding: "5px",
                textAlign: "center",
              }}
              className="transfer-watch-nmb "
            >
              {data.transactionCount}
            </div>
          </div>

          <div
            className="ms-2 me-2"
            style={{ border: "1px solid #E2E2E2" }}
          ></div>
        </div>
      ))
    );
  };
  return (
    <div style={{ background: "#fff", borderRadius: "10px" }}>
      {transfers?.transactions?.length > 0 ? (
        RenderItems()
      ) : (
        <div className="statistics-card-text d-flex justify-content-center p-5">
          No Transfers Found
        </div>
      )}
      <div
        className="transfer-watches-text d-flex justify-content-start ps-3 pt-3 pb-2"
        onClick={() => {
          navigate("/watch");
        }}
      >
        View All Watches
        <img src={arrow} alt="icon" className="ps-1" />
      </div>
    </div>
  );
}

export default TransferWatches;
