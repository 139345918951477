import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { fetchDetailWatch } from "../../store/detailWatchSlice";
import { fetchGetTransaction } from "../../store/getTransactionSlice";
import { fetchGetMetadata } from "../../store/metadataSlice";
import { STATUSES } from "../../store/detailWatchSlice";
import { imageURL } from "../../axios";
import dateFormat from "dateformat";
import Tabs from "../Tabs/tabs";
import Logout from "../Logout/logout";
import Transfer from "../Watches/transfer/transfer";

import watchImg from "../../assets/images/watch-details-img.png";
import Icon1 from "../../assets/images/watch-details-icon1.svg";
import Icon2 from "../../assets/images/watch-details-icon2.svg";
import Icon3 from "../../assets/images/watch-details-icon3.svg";
import Icon4 from "../../assets/images/watch-details-icon4.svg";
import user from "../../assets/images/person-icon.svg";
import back from "../../assets/images/back.svg";
import addIcon from "../../assets/images/Add_round.svg";
import "./details.css";

function Details() {
  const params = useParams();
  const navigate = useNavigate();

  const id = params.id;

  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [transferId, setTransferId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { data: watchData, status } = useSelector((state) => state.detailWatch);
  // console.log(watchData);
  const [transaction, setTransaction] = useState();

  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchDetailWatch({ id, token }));
      dispatch(fetchGetTransaction({ id, token }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setTransaction(res.transaction[0]);
          }
        });
    }
  }, [params.id, isLoading]);

  const { theme } = useSelector((store) => store.theme);

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  function handleTransfer(data) {
    setTransferId(data);
  }
  async function handleCertificate(id) {
    console.log(id);
    dispatch(fetchGetMetadata({ id, token }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          console.log(res);
        }
      });
  }

  const TransactionTable = (transactions) => {
    const formatDate = (timestamp) => {
      const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
      return date.toLocaleDateString(); // Get the formatted date string
    };
    return (
      <div
        className="transaction-table-container p-2"
        style={{ backgroundColor: "#fff", borderRadius: "10px" }}
      >
        <table className="transaction-table">
          <thead className="details-page-heading">
            <tr>
              <th>Token ID</th>
              <th>From</th>
              <th>To</th>
              <th>Hash</th>
              <th>Block Number</th>
              <th>Timestamp</th>
            </tr>
          </thead>

          <tbody className="details-page-text">
            {transactions.transaction.map((transaction, index) => (
              <tr key={index}>
                <td>{transactions.certificateID}</td>
                <td>{transaction.from}</td>
                <td>{transaction.to}</td>
                <td>{transaction.transactionHash}</td>
                <td>{transaction.blockNumber}</td>
                <td>{formatDate(transaction.timestamp, "mmm dS, yyyy")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (status === STATUSES.ERROR) {
    return <div className="failed-text">Something went wrong!</div>;
  }
  function handleBackClick() {
    navigate("/watch");
  }
  return (
    <div
      className={`d-flex flex-row bg-${theme}`}
      style={{ minHeight: "100vh" }}
    >
      <Tabs />
      <div className="w-100">
        <div
          className="d-flex justify-content-between ps-3 pt-2 pb-2"
          style={{
            background: "#FFFFFF",
            border: "1px solid #E2E2E2",
            borderRadius: "5px",
          }}
        >
          <button
            onClick={handleBackClick}
            className="details-page-main-heading ps-4"
            style={{ background: "none", border: "none" }}
          >
            <img src={back} alt="icon" className="pe-3" />
            Watches
          </button>
          <Logout />
        </div>
        <div className="container-fluid p-lg-2 p-1">
          {status === STATUSES.LOADING || isLoading ? (
            <div className="d-flex justify-content-center align-items-center p-5">
              <div className="spinner-border text-secondary" role="status">
                {/* <span className="visually-hidden">Loading...</span> */}
              </div>
            </div>
          ) : (
            <div>
              <div className="d-lg-flex pt-3 pe-2">
                <div className="d-flex justify-content-center col-lg-3 col-12 pb-3">
                  <img
                    src={`${imageURL}uploads/` + watchData?.image}
                    alt="watch"
                    style={{ maxWidth: "75%", maxHeight: "150px" }}
                  />
                </div>

                <div
                  className="p-3 col-lg-9 col-12"
                  style={{ backgroundColor: "#fff", borderRadius: "10px" }}
                >
                  <div className="details-page-main-heading pb-3">
                    {watchData?.watchName}
                  </div>

                  {watchData?.isCertified === true ? (
                    <>
                      <div className="d-sm-block d-flex">
                        <div className=" d-sm-flex justify-content-start align-items-center details-page-heading col-sm-12 col-6 pb-2">
                          <div className="col-sm-2 mb-sm-0 mb-2">Status</div>
                          <div className="col-sm-2 mb-sm-0 mb-2">Price</div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            Certified Date
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            Created Date
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            Serial No.
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">Owner</div>
                        </div>
                        <div className=" d-sm-flex justify-content-start align-items-center details-page-text col-sm-12 col-6 pb-3">
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            <span className="detail-status ps-1 pe-1">
                              Certified
                            </span>
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {watchData?.price}
                            {` CHF (Tax + VAT)`}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {dateFormat(
                              watchData?.cerrtifiedAt,
                              "mmm dS, yyyy"
                            )}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {dateFormat(watchData?.createdAt, "mmm dS, yyyy")}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {watchData?.serialNumber}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {watchData?.clientFirstName +
                              ` ` +
                              watchData?.clientLastName}
                          </div>
                        </div>
                      </div>
                      <div
                        className="ms-1 mb-2"
                        style={{ border: "1px solid #E2E2E2" }}
                      ></div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <a
                            href={`${watchData?.pdf}`}
                            target="_blank"
                            download
                            className="text-decoration-none transfer-btn p-1"
                          >
                            Download Pdf
                          </a>
                        </div>
                        <div>
                          <img src={Icon2} alt="icon" className="p-1" />
                          <img src={Icon1} alt="icon" className="p-1" />
                          <img src={Icon3} alt="icon" className="p-1" />
                          <img src={Icon4} alt="icon" className="p-1" />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-sm-block d-flex">
                        <div className="d-sm-flex justify-content-start align-items-center details-page-heading col-sm-12 col-6 pb-2">
                          <div className="col-sm-3 mb-sm-0 mb-2">Status</div>
                          <div className="col-sm-3 mb-sm-0 mb-2">Price</div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            Created Date
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            Serial No.
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">Owner</div>
                        </div>
                        <div className="d-sm-flex justify-content-start align-items-center details-page-text col-sm-12 col-6 pb-3">
                          <div className="col-sm-3 mb-sm-0 mb-2">
                            <span className="detail-status2 ps-2 pe-2">
                              Not Certified
                            </span>
                          </div>
                          <div className="col-sm-3 mb-sm-0 mb-2">
                            {watchData?.price}
                            {` CHF (Tax + VAT)`}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2 pe-1">
                            {dateFormat(watchData?.createdAt, "mmm dS, yyyy")}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {watchData?.serialNumber}
                          </div>
                          <div className="col-sm-2 mb-sm-0 mb-2">
                            {watchData?.clientFirstName +
                              ` ` +
                              watchData?.clientLastName}
                          </div>
                        </div>
                      </div>
                      <div
                        className="ms-1 mb-2"
                        style={{ border: "1px solid #E2E2E2" }}
                      ></div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <button
                            className="transfer-btn"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                            onClick={() => {
                              handleTransfer(watchData);
                            }}
                          >
                            ADD CERTIFICATE <img src={addIcon} alt="icon" />
                          </button>
                        </div>
                        <div>
                          <img src={Icon2} alt="icon" className="p-1" />
                          <img src={Icon1} alt="icon" className="p-1" />
                          <img src={Icon3} alt="icon" className="p-1" />
                          <img src={Icon4} alt="icon" className="p-1" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="d-lg-flex justify-content-end">
                <div
                  className="col-lg-5 col-12 pt-3"
                  style={{ height: "fit-content" }}
                >
                  <div className={`details-container-heading-${theme} ps-2`}>
                    Case
                  </div>
                  <div
                    className="p-3 m-2"
                    style={{ background: "#fff", borderRadius: "10px" }}
                  >
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Finishes</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseFinishes
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Height</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseHeight
                          : null}
                        mm
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Strap</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseStrap
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">
                        Screwed-in lugs
                      </p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseScrewedInLugs
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Diameter</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseDiameter
                          : null}
                        mm
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">
                        Waterproofing
                      </p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseWaterProofing
                          : null}
                        m
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Clasp</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseClasp
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Case back</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.caseBack
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-12 pt-3"
                  style={{ height: "fit-content" }}
                >
                  <div className={`details-container-heading-${theme} ps-2`}>
                    Dial
                  </div>
                  <div
                    className="p-3 m-2"
                    style={{ background: "#fff", borderRadius: "10px" }}
                  >
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Glass</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.dialGlass
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Center Dial</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.dialCenterDial
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Complication</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.dialComplication
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">
                        Exterior elevation
                      </p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.dialExteriorElevation
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Hands</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.dialHands
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-lg-flex justify-content-end pb-5">
                <div
                  className="col-lg-5 col-12 pt-3"
                  style={{ height: "fit-content" }}
                >
                  <div className={`details-container-heading-${theme} ps-2`}>
                    Crown
                  </div>
                  <div
                    className="p-3 m-2 row"
                    style={{ background: "#fff", borderRadius: "10px" }}
                  >
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Info</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.crownInfo
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Material</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.crownMaterial
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-12 pt-3 pt-lg-0"
                  style={{ height: "fit-content" }}
                >
                  <div className={`details-container-heading-${theme} ps-2`}>
                    Movement
                  </div>
                  <div
                    className="p-3 m-2 row"
                    style={{ background: "#fff", borderRadius: "10px" }}
                  >
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Automatic</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.movementAutomatic
                          : null}
                      </p>
                    </div>
                    <div style={{ border: "1px solid #E2E2E2" }}></div>
                    <div className="d-flex pt-2 pb-2">
                      <p className="details-page-heading col-5">Access</p>
                      <p className="details-page-text col-6">
                        {watchData?.attributes
                          ? watchData?.attributes.movementAccess
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-lg-flex justify-content-end">
                {transaction?.transaction && watchData?.isCertified && (
                  <div className="col-lg-9 col-12">
                    <div
                      className={` details-container-heading-${theme} ps-2 pe-2 pb-2`}
                    >
                      Transaction History
                    </div>

                    {TransactionTable(transaction)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Transfer data={transferId} setIsLoading={setIsLoading} />
    </div>
  );
}

export default Details;
