import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { themeToggler } from "../../../store/themeSlice";
import { menuBar } from "../../../store/menuBarSlice";
import user from "../../../assets/images/person-icon.svg";
import search from "../../../assets/images/Search_alt.svg";
import menuIcon from "../../../assets/images/menu.svg";
import Logout from "../../Logout/logout";
function Header() {
  const { theme } = useSelector((store) => store.theme);
  const dispatch = useDispatch();

  const res = localStorage.getItem("userData");
  const data = JSON.parse(res);
  const [firstName, setFirstName] = useState("");

  const [searchBar, setSearchBar] = useState("");

  useEffect(() => {
    setFirstName(data.firstName);
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  return (
    <div
      className="d-flex justify-content-between p-md-3 pt-3 pb-3"
      style={{
        background: "#FFFFFF",
        border: "1px solid #E2E2E2",
        borderRadius: "5px",
      }}
    >
      <div className="d-flex">
        <div
          className={`d-flex flex-row align-items-center header-text me-md-5 me-2 col-md-6 ps-md-0 ps-3`}
        >
          Good Morning, {firstName}!
        </div>
        <div className="d-flex justify-content-between search-bar ps-2 col-md-6">
          <input
            type="text"
            style={{ background: "none", border: "none" }}
            placeholder="Search here..."
          />
          <div>
            <img
              className="input-group-text"
              src={search}
              alt=""
              style={{ background: "none", border: "none" }}
            />
          </div>
        </div>
      </div>

      <Logout />
    </div>
  );
}

export default Header;
