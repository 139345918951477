import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const watchSlice = createSlice({
  name: "watch",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setWatches(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatches.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchWatches.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchWatches.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setWatches, setStatus } = watchSlice.actions;
export default watchSlice.reducer;

// Thunks
export const fetchWatches = createAsyncThunk("watches/fetch", async (data) => {
  const filter = data.pageStatus;
  const page = data.page;
  const search = data.searchInput;

  const config = {
    headers: { Authorization: "Bearer " + data.token },
  };
  const response = await axios.get(
    `watch/get-all-watches/${filter}/${page}/${search}`,
    config
  );

  const res = await response.data;
  return res;
});


export const fetchAllWatches = createAsyncThunk("watches/fetch", async (data) => {
  const filter = data.pageStatus;
  const page = data.page;
  const search = data.searchInput;
  const sortField = data.sortField;
  const sortOrder = data.sortOrder;

  const config = {

    headers: { Authorization: "Bearer " + data.token },
  };

  const response = await axios.get(
    `user-watches/get-all-watches-of-merchant?filter=${filter}&page=${page}&search=${search}&sortField=${sortField}&sortOrder=${sortOrder}`,
    config
  );
  // const response = await axios.post(
  //   `user-watches/get-all-watches`,   payload,
  //   config
  // );
  const res = await response.data;
  return res;
});

