import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const GetTransaction = createSlice({
  name: "transactionData",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setGetTransaction(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetTransaction.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchGetTransaction.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchGetTransaction.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setGetTransaction, setStatus } = GetTransaction.actions;
export default GetTransaction.reducer;

// Thunks
export const fetchGetTransaction = createAsyncThunk(
  "GetTransaction/fetch",
  async (data) => {
    const config = {
      headers: { Authorization: "Bearer " + data.token },
    };

    const response = await axios.get(
      `/analytics/transaction/` + data.id,
      config
    );
    const res = await response.data;
    //console.log(response);
    return res;
  }
);
