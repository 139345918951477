import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const updateMerchantSlice = createSlice({
  name: "merchant",
  initialState: {
    data: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    setMerchant(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateMerchant.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateMerchant.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(updateMerchant.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(inviteCollaborator.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(inviteCollaborator.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(inviteCollaborator.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setMerchant, setStatus } = updateMerchantSlice.actions;
export default updateMerchantSlice.reducer;

export const inviteCollaborator = createAsyncThunk(
  "merchant/addCollaborator",
  async (data) => {
    try {
      const config = {
        headers: {
          Authorization: "Bearer " + data.token,
        },
      };

      console.log("In inviteCollaborator", data);

      const response = await axios.post(
        `auth/addCollaborator`,
        data.payload,
        config
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Collaborator successfully invited.",
          confirmButtonColor: "#4182EB",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed, unable to invite collaborator.",
          confirmButtonColor: "#4182EB",
        });
      }
      console.log(response);
      return response.data;
    } catch (error) {
      if(error.status === 409)
        Swal.fire({
          icon: "error",
          title: "Already Invited",
          text: "Invite has been already sent to collaborator.",
          confirmButtonColor: "#4182EB",
        });
        else
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed, unable to invite collaborator.",
        confirmButtonColor: "#4182EB",
      });
      // Handle errors here
      console.error("Error inviting collaborator:", error);
      // throw error;
    }
  }
);


// export const inviteCollaborator= createAsyncThunk(
//   "merchant/addCollaborator",
//   async (data) => {
//     const config = {
//       headers: {
//         Authorization: "Bearer " + data.token,
//       },
//     };
//     console.log(
//       "in inviteCollaborator" , data
//     )
//     const response = await axios.post(
//       `auth/addCollaborator`,
//       data.payload,
//       config
//     );
//     if (response.data.success) {

//       Swal.fire({
//         icon: "success",
//         title: "Success",
//         text: "Collaborator successfully invited.",
//         confirmButtonColor: "#4182EB",
//       });
//     } else {
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed,unable to invite collaborator.",
//         confirmButtonColor: "#4182EB",
//       });
//     }
//     console.log(response);
//     return response.data;
//   }
// );


// Thunks
export const updateMerchant = createAsyncThunk(
  "merchant/update",
  async (data) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + data.token,
      },
    };
    const response = await axios.post(
      `auth/update-merchant`,
      data.formData,
      config
    );
    if (response.data.success) {
      localStorage.setItem("userData", JSON.stringify(response.data.merchant));

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "User data updated successfully.",
        confirmButtonColor: "#4182EB",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed, User data not updated.",
        confirmButtonColor: "#4182EB",
      });
    }
    console.log(response);
    return response.data;
  }
);
