import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const verifyTokenSlice = createSlice({
  name: "token",
  initialState: {
    data: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    setToken(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyToken.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setToken, setStatus } = verifyTokenSlice.actions;
export default verifyTokenSlice.reducer;

// Thunks
export const verifyToken = createAsyncThunk("token/verify", async (token) => {
  const body = {
    token: token,
  };

  const response = await axios.post(`auth/verify`, body);
  const data = await response.data;

  return data.verified;
});
