import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveContainer } from "recharts";
import { fetchGraphData } from "../../../store/GraphDataSlice";

import ReactApexChart from "react-apexcharts";

function Graph(props) {
  let status = props.status;
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const { data: GraphData } = useSelector((state) => state.GraphData);

  
  
// const GraphData=  {
    
//     "series": [
//         {
//             "name": 2023,
//             "data": [
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0
//             ]
//         },
//         {
//             "name": 2024,
//             "data": [
//                 10,
//                 15,
//                 7,
//                 9,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0,
//                 0
//             ]
//         }
//     ],
//     "msg": "Watch successfully"
// }

  useEffect(() => {
    if (status === "All Watches")
      dispatch(fetchGraphData({ token, status: "Watch" }));
    if (status === "Certified Watches")
      dispatch(fetchGraphData({ token, status: "Certified" }));
    if (status === "All Clients")
      dispatch(fetchGraphData({ token, status: "Client" }));
  }, [status]);

  // const series = [
  //   {
  //     name: "2021",
  //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 30, 60, 90],
  //   },
  //   {
  //     name: "2022",
  //     data: [20, 31, 25, 61, 69, 72, 79, 81, 148, 30, 90, 100],
  //   },
  // ];
  // console.log(GraphData);
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },

    colors: ["#9EA4AD", "#4182EB"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right",
      floating: true,
      offsetY: 25,
      offsetX: -5,
    },
  };

  return (
    <div className="" style={{ background: "#fff" }}>
      {GraphData?.series && (
        <ResponsiveContainer width="100%" height={300}>
          <ReactApexChart
            options={options}
            series={GraphData.series}
            type="line"
            height={350}
          />
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default Graph;
