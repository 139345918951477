import axios from "axios";
import {baseURLClient} from "../../axios";


export const saveUpdatedAttributes = async (
  payload,
  setUpdateStatus,
  props
) => {
  try {
    const response = await axios.post(
      // "https://twiny2.thecbt.live/api/watch/maintain-watch-w3",
      `${baseURLClient}watch/maintain-watch-w3`,
      payload
    );

    if (response.data.success) {
      setUpdateStatus({
        loading: false,
        success: true,
        error: null,
      });
      props.setMetaModalClose();
    }
  } catch (error) {
    setUpdateStatus({
      loading: false,
      success: false,
      error: error.response.data.msg,
    });
    console.error(error);
  }
};

export const verifyOtp = async (props, otp, handleSave, setUpdateStatus) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${baseURLClient}users/verifyOTP`,
      {
        _email: props.watch.email,
        otp: otp,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success) {
      handleSave();
    }
  } catch (error) {
    setUpdateStatus({
      loading: false,
      success: false,
      error: error.response.data.msg,
    });
    console.error({ error });
  }
};

export const fetchWatchDetails = async (
  props,
  setWatch,
  setWatchAttributes,
  setStatus,
  setUpdateStatus
) => {
  try {
    const token = localStorage.getItem("token");
    const payload = {
      watchId: props.watch.watchId,
      _id: props.watch._id,
    };


    const response = await axios.post(
      `${baseURLClient}watch/get-watch-by-watch-id`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setWatch(response.data.watch);
    setWatchAttributes(response.data.watch.attributes);
    setStatus({ loading: false, success: true, error: null });
  } catch (error) {
    console.error(error);
    setUpdateStatus({
      loading: false,
      success: false,
      error: error.response.data.msg,
    });
  }
};

export const _fetchWatchDetails = async (
  props,
  setWatch,
  setWatchAttributes,
  setStatus,
  setUpdateStatus,
  setWatchTransaction,

) => {
  try {
    const token = localStorage.getItem("token");
    const payload = {
      watchId: props.watch.watchId,
      _id: props.watch._id,
    };

    const response = await axios.post(
      // "https://twiny2.thecbt.live/api/watch/get-watch-by-watch-id",
     `${baseURLClient}watch/get-watch-by-watch-id`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );



    setWatch(response.data.watch);
    setWatchAttributes(response.data.watch.attributes);
    setWatchTransaction({
      timestamp:null,
      maintenance:  response.data.Maintenance ,
      transfer:response.data.Transfer,
      warranty:response.data.Warranty,
      // response.data.transactionDetail.Hash? [
      // {  certificateID: response.data.transactionDetail.Timestamp,
      // from: 
      // response.data.transactionDetail.From
      // ,
      // to: 
      // response.data.transactionDetail.To
      // ,
      // transactionHash: response.data.transactionDetail.Hash,
      // blockNumber: response.data.transactionDetail.BlockNo,
      // timestamp: response.data.transactionDetail.Timestamp,  }
      // ]:null,


      certificate:response.data.certificate
    })

    setStatus({ loading: false, success: true, error: null });
  } catch (error) {
    console.error(error);
    setUpdateStatus({
      loading: false,
      success: false,
      error: error.response.data.msg,
    });
  }
};

export const sendOTPRequest = async (
  props,
  setOtpExpirationTime,
  setUpdateStatus
) => {
  try {
    const expirationTime = Date.now() + 120000; // 2 minutes in milliseconds
    setOtpExpirationTime(expirationTime);

    const token = localStorage.getItem("token");
    const response = await axios.post(
      // "https://twiny2.thecbt.live/api/users/sendOTP",
      `${baseURLClient}users/sendOTP`,
      {
        _email: props.watch.email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    setUpdateStatus({
      loading: false,
      success: false,
      error: error.response.data.msg,
    });
    console.error({ error });
  }
};
