import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const highestTransfer = createSlice({
  name: "highestTransfer",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setHighTransfers(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHighTransfers.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchHighTransfers.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchHighTransfers.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setHighTransfers, setStatus } = highestTransfer.actions;
export default highestTransfer.reducer;

// Thunks
export const fetchHighTransfers = createAsyncThunk(
  "highestTransfer/fetch",
  async (token) => {
    const config = {
      headers: { Authorization: "Bearer " + token },
    };

    const response = await axios.get(`/analytics/highest-transfer`, config);
    const res = await response.data;
    //console.log(response);
    return res;
  }
);
