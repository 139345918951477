import React from 'react';
import "./mantainance.css"

export  const  TransactionTable = ({ transactions }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
    return date.toLocaleDateString(); // Get the formatted date string
  };

  return (
    <div
      className="transaction-table-container-1"
      style={{ backgroundColor: "#fff", borderRadius: "10px" }}
    >
      <table className="transaction-table">
        <thead
        style={{
            fontFamily:"Poppins",
            fontWeight:550,
            fontSize:"12px",
            lineHeight:"12px",
            color: "#414141",
            padding:0,
            margin:0
        }}
        // className="details-page-heading"
        
        >
          <tr>
            <th className='th-transaction-1' >Token ID</th>
            <th className='th-transaction-1'>From</th>
            <th  className='th-transaction-1'>To</th>
            <th  className='th-transaction-1'>Hash</th>
            <th  className='th-transaction-1'>Block Number</th>
            <th  className='th-transaction-1'>Timestamp</th>
          </tr>
        </thead>
        <tbody className="details-page-text">
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.certificateID}</td>
              <td>{transaction.from}</td>
              <td>{transaction.to}</td>
              <td>{transaction.transactionHash}</td>
              <td>{transaction.blockNumber}</td>
              <td>{formatDate(transaction.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
