import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteWatch } from "../../../store/deleteWatchSlice";
import { fetchWatches } from "../../../store/watchSlice";
import { imageURL } from "../../../axios";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import yesIcon from "../../../assets/images/yes-icon.svg";

import "./delete.css";

function Delete(props) {
  const _id = props.id._id;
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  async function handleDelete() {
    let res = await dispatch(deleteWatch({ _id, token }));

    if (res.payload.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Watch deleted successfully.",
        confirmButtonColor: "#4182EB",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Watch not deleted.",
        confirmButtonColor: "#4182EB",
      });
    }
    props.setDeleteButton(true);
    props.setShowModalDelete(false);
  }

  const handleClose = () => props.setShowModalDelete(false);
  
  return (
    <div>
      <Modal
        show={props.showModalDelete}
        onHide={handleClose}
        centered
        contentClassName="m-sm-5"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center pb-3 pt-3">
            <div className="d-flex text-center delete-watch-form-heading col-sm-8 col-11">
              Are you sure to delete the following item?
            </div>
          </div>
          <div
            className="ms-4 me-4"
            style={{ border: "1px solid #E2E2E2", borderRadius: "120px" }}
          ></div>
          <div className="d-flex align-items-center pt-3 pb-3 col-sm-10 col-12">
            <div className="col-3">
              <img
                src={props.id.image}
                className="w-100 pe-3"
              />
            </div>
            <div>
              <div className="delete-watch-form-text">
                {props.id.watch_name}
              </div>
              <div className="d-flex delete-watch-form-text2 pt-2">
                {props.id.email}
                <div className="d-flex align-items-center delete-watch-form-text3 ms-2 ps-2 pe-2">
                  owner
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center p-2">
            <button
              onClick={handleDelete}
              className="delete-watch-btn pt-2 pb-2 ps-4 pe-3 me-2"
            >
              YES <img src={yesIcon} alt="" className="ms-2" />
            </button>
            <button
              className="delete-watch-btn2 pt-2 pb-2 ps-4 pe-4"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Delete;
