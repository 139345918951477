import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const updateWatchSlice = createSlice({
  name: "watch",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setWatches(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateWatch.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateWatch.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(updateWatch.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setWatches, setStatus } = updateWatchSlice.actions;
export default updateWatchSlice.reducer;

// Thunks
export const updateWatch = createAsyncThunk("watch/update", async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + data.token,
    },
  };
  //console.log(data._id);
  const response = await axios
    .patch(`watch/update-watch/` + data._id, data.formData, config)
    .catch((error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Watch not updated.Something went wrong!!!",
        confirmButtonColor: "#4182EB",
      });
    });
  console.log(response.data);
  if (response.data.success) {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Watch updated successfully.",
      confirmButtonColor: "#4182EB",
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Watch not updated.",
      confirmButtonColor: "#4182EB",
    });
  }
  return response.data;
});
