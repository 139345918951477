import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
let data;
const twoFATogglerSlice = createSlice({
  name: "twoFA",
  initialState: {
    data,
    status: STATUSES.IDLE,
  },
  reducers: {
    setToken(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(twoFAToggler.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(twoFAToggler.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(twoFAToggler.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setToken, setStatus } = twoFATogglerSlice.actions;
export default twoFATogglerSlice.reducer;

// Thunks
export const twoFAToggler = createAsyncThunk("twoFA/verify", async (data) => {
  const response = await axios.post(`auth/2factor`, data);
  if (response.data.status)
    localStorage.setItem("userData", JSON.stringify(response.data.merchant));
  console.log(response);
  return response.data;
});
