import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const addCertificateSlice = createSlice({
  name: "certificate",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setCertificate(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCertificate.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addCertificate.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(addCertificate.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setCertificate, setStatus } = addCertificateSlice.actions;
export default addCertificateSlice.reducer;

// Thunks
export const addCertificate = createAsyncThunk(
  "certificate/add",
  async (data) => {
    const config = {
      headers: {
        Authorization: "Bearer " + data.token,
      },
    };
    const response = await axios.post("nft/generate-certificate", data, config);
    console.log(response.data);
    return response.data;
  }
);
