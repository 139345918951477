import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { updateWatch } from "../../../store/updateWatchDetailSlice";
import { fetchWatches } from "../../../store/watchSlice";
import { imageURL } from "../../../axios";
import { fetchClientsName } from "../../../store/clientsNameSlice";
import { STATUSES } from "../../../store/clientsNameSlice";
import uploadIcon from "../../../assets/images/upload-icon.svg";
import updateIcon from "../../../assets/images/check_ring_round.svg";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "./update.css";

import transferIcon from "../../../assets/images/transfer-watch-icon.svg";

function Update(props) {
  const [fields, setFields] = useState({
    image: props.id.image,
    watch_name: props.id.watch_name,
    brand_name: props.id.brand_name,
    owner_firstname: "",
    owner_lastname: "",
    email: props.id.email,
  });
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem("token");
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [imageUrl, setImageUrl] = useState("");

  const dispatch = useDispatch();

  const [addNewClient, setAddNewClient] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (value, label) => {
    let text = value.label.split(" ");
    let email = value.email;
    setFields((prevState) => ({
      ...prevState,
      ["owner_firstname"]: text[0],
    }));
    setFields((prevState) => ({
      ...prevState,
      ["owner_lastname"]: text[1],
    }));
    setFields((prevState) => ({
      ...prevState,
      ["email"]: email,
    }));
    setSelectedOption(value);
  };

  useEffect(() => {
    dispatch(fetchClientsName(token));
  }, []);

  const { data: clientsNameData, status } = useSelector(
    (state) => state.clientsName
  );
  const options = clientsNameData.clients?.map((client) => ({
    value: client._id,
    email: client.email,
    label: `${client.clientFirstName} ${client.clientLastName}`,
  }));

  const index = clientsNameData.clients?.findIndex(
    (item) => item._id === props.id.clientId
  );

  useEffect(() => {
    if (!addNewClient && index !== -1) {
      handleSelect(options[index]);
    }
    if (addNewClient) {
      setFields({
        image: props.id.image,
        watch_name: props.id.watch_name,
        brand_name: props.id.brand_name,
        owner_firstname: "",
        owner_lastname: "",
        email: "",
      });
    }
  }, [props, clientsNameData, addNewClient]);

  const REQUIRED_FIELDS = ["image", "watch_name", "brand_name"];

  const validateField = (fieldName, value) => {
    const pattern = /^[a-zA-Z].*/;
    switch (fieldName) {
      case "image":
        if (!value) return "Image is required.";
        if (value.size > 100000000)
          return "File size must be less than or equal to 100MB.";
        break;
      case "watch_name":
        if (!value) return "Watch name is required.";
        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "brand_name":
        if (!value) return "Brand name is required.";
        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "owner_firstname":
        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "owner_lastname":
        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "email":
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
          return "Invalid email format.";
        break;
      default:
        break;
    }
    return "";
  };

  const handleInputChange = (event) => {
    if (event.type === "change") {
      const { name, value } = event.target;

      // Validate input before updating state
      const errorMessage = validateField(name, value);
      setErrors({
        ...errors,
        [name]: errorMessage,
      });
      const isNested = name.includes(".");
      if (isNested) {
        // If the property is nested, split the name and create a new object with the updated state
        const [parentKey, childKey] = name.split(".");
        setFields((prevState) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [childKey]: value,
          },
        }));
      } else {
        // If the property is not nested, update the state directly
        setFields((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setImageUrl(URL.createObjectURL(event));
      const errorMessage = validateField("image", event);
      setErrors({
        ...errors,
        ["image"]: errorMessage,
      });
      setFields({ ...fields, ["image"]: event });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate required fields
    const newErrors = {};
    REQUIRED_FIELDS.forEach((fieldName) => {
      const isNested = fieldName.includes(".");
      if (!isNested) {
        const errorMessage = validateField(fieldName, fields[fieldName]);
        if (errorMessage) newErrors[fieldName] = errorMessage;
      } else {
        const [parentKey, childKey] = fieldName.split(".");
        const errorMessage = validateField(
          fieldName,
          fields.attributes[childKey]
        );
        if (errorMessage) newErrors[fieldName] = errorMessage;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit form
      console.log("Form submitted!");

      let _id = props.id._id;
      let formData = new FormData();
      formData.append("image", fields.image);
      formData.append("watch_name", fields.watch_name);
      formData.append("brand_name", fields.brand_name);
      formData.append("owner_firstname", fields.owner_firstname);
      formData.append("owner_lastname", fields.owner_lastname);
      formData.append("email", fields.email);
      if (!addNewClient && selectedOption) {
        formData.append("clientId", selectedOption.value);
      }
      dispatch(updateWatch({ formData, _id, token }));
      dispatch(fetchClientsName(token));
      props.setUpdateButton(true);
      props.setShowModalUpdate(false);
      setImageUrl("");
    }
  };

  const handleClose = () => {
    props.setShowModalUpdate(false);
    setFields({
      image: props.id.image,
      watch_name: props.id.watch_name,
      brand_name: props.id.brand_name,
      owner_firstname: "",
      owner_lastname: "",
      email: props.id.email,
    });
    setErrors({});
  };
  return (
    <Modal
      show={props.showModalUpdate}
      onHide={handleClose}
      centered
      contentClassName="w-75 m-5"
    >
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between pb-3">
            <div className="update-watch-form-heading">Update a Watch</div>
            <button
              type="button"
              className="btn-close p-1"
              onClick={handleClose}
              style={{
                backgroundColor: "#E2E2E2",
                borderRadius: "200px",
              }}
            ></button>
          </div>
          <div className="d-flex justify-content-center align-items-center fileDrag mb-1">
            <FileUploader
              type="file"
              name="image"
              handleChange={handleInputChange}
              types={fileTypes}
              children={
                <div>
                  {imageUrl ? (
                    <div className="d-flex flex-column align-items-center pt-3 pb-3">
                      <img
                        className="w-50 pb-2"
                        src={imageUrl}
                        alt="img"
                        style={{ maxHeight: "200px" }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center pt-3 pb-3">
                      <img
                        className="w-50 pb-2"
                        src={`${imageURL}uploads/` + fields.image}
                        style={{ maxHeight: "200px" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>
              }
            />
          </div>
          {errors.image && (
            <span className="error-field d-flex justify-content-center mb-2">
              {errors.image}
            </span>
          )}
          <div className="d-sm-flex flex-row">
            <div className="d-flex flex-column me-sm-4">
              <span className="update-watch-form-text">Watch Name</span>
              <input
                type="text"
                name="watch_name"
                value={fields.watch_name}
                onChange={handleInputChange}
                className="input-box mb-2 p-1"
              />
              {errors.watch_name && (
                <span className="error-field">{errors.watch_name}</span>
              )}
            </div>
            <div className="d-flex flex-column">
              <span className="update-watch-form-text">Brand Name</span>
              <input
                type="text"
                name="brand_name"
                value={fields.brand_name}
                onChange={handleInputChange}
                className="input-box mb-2 p-1"
              />
              {errors.brand_name && (
                <span className="error-field">{errors.brand_name}</span>
              )}
            </div>
          </div>

          {!addNewClient ? (
            <div className="d-flex">
              <div className="d-flex flex-column" style={{ width: "165px" }}>
                <label className="update-watch-form-text">
                  Select your name
                </label>
                <div>
                  <Select
                    className="update-watch-form-text mb-2"
                    options={options}
                    onChange={handleSelect}
                    defaultValue={options[index]}
                  />
                </div>
              </div>
              <div
                className=" mt-4 ms-1"
                onClick={() => {
                  setAddNewClient(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  data-toggle="tooltip"
                  title="Add New Client"
                  src={transferIcon}
                  alt="icon"
                  className=""
                  style={{ background: "#4182EB", borderRadius: "50%" }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="d-sm-flex">
                <div className="col-sm-6 d-flex flex-column pe-sm-2">
                  <span className="update-watch-form-text">
                    Owner First Name
                  </span>
                  <input
                    type="text"
                    name="owner_firstname"
                    value={fields.owner_firstname}
                    onChange={handleInputChange}
                    className="input-box mb-2 p-1"
                  />
                  {errors.owner_firstname && (
                    <span className="error-field">
                      {errors.owner_firstname}
                    </span>
                  )}
                </div>

                <div className="d-flex col-sm-6">
                  <div className="d-flex flex-column ps-sm-3">
                    <span className="update-watch-form-text">
                      Owner Last Name
                    </span>

                    <input
                      type="text"
                      name="owner_lastname"
                      value={fields.owner_lastname}
                      onChange={handleInputChange}
                      className="input-box mb-2 p-1"
                    />
                    {errors.owner_lastname && (
                      <span className="error-field">
                        {errors.owner_lastname}
                      </span>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      setAddNewClient(false);
                    }}
                    className="ms-1"
                    style={{ cursor: "pointer", marginTop: "18px" }}
                  >
                    <img
                      data-toggle="tooltip"
                      title="Choose Old Client"
                      src={transferIcon}
                      alt="icon"
                      className=""
                      style={{ background: "#4182EB", borderRadius: "50%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <span className="update-watch-form-text">Email</span>
                <input
                  type="text"
                  name="email"
                  value={fields.email}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.email && (
                  <span className="error-field">{errors.email}</span>
                )}
              </div>
            </>
          )}

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="update-watch-btn pt-2 pb-2 ps-4 pe-3"
            >
              UPDATE <img src={updateIcon} alt="icon" className="ps-1" />
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Update;
