import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import updateIcon from "../../../assets/images/check_ring_round.svg";
import Modal from "react-bootstrap/Modal";

import "./update.css";
import { addClient } from "../../../store/updateClientSlice";

function AddUpdateClient(props) {
  const [fields, setFields] = useState({
    owner_firstname: props.id.clientFirstName??"",
    owner_lastname: props.id.clientLastName??"",
    email: props.id.email??"",
  });
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  useEffect(() => {
    setFields({
        owner_firstname: props.id.clientFirstName??"",
        owner_lastname: props.id.clientLastName??"",
        email: props.id.email??"",
    });
  }, [props]);
  const REQUIRED_FIELDS = ["owner_firstname", "owner_lastname", "email"];

  const validateField = (fieldName, value) => {
    const pattern = /^[a-zA-Z].*/;
    switch (fieldName) {
      case "owner_firstname":   
        if (!value) return "Owner first name is required.";
        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "owner_lastname":
        if (!value) return "Owner last name is required.";
        if (!pattern.test(value)) return "Input is not valid.";
        break;
      case "email":
        if (!value) return "Email is required.";
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
          return "Invalid email format.";
        break;
      default:
        break;
    }
    return "";
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Validate input before updating state
    const errorMessage = validateField(name, value);
    setErrors({
      ...errors,
      [name]: errorMessage,
    });
    const isNested = name.includes(".");
    if (isNested) {
      // If the property is nested, split the name and create a new object with the updated state
      const [parentKey, childKey] = name.split(".");
      setFields((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    } else {
      // If the property is not nested, update the state directly
      setFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = localStorage.getItem("userData");

    // Parse user data
    const parsedUserData = JSON.parse(userData);

    // Validate required fields
    const newErrors = {};
    REQUIRED_FIELDS.forEach((fieldName) => {
      const isNested = fieldName.includes(".");
      if (!isNested) {
        const errorMessage = validateField(fieldName, fields[fieldName]);
        if (errorMessage) newErrors[fieldName] = errorMessage;
      } else {
        const [parentKey, childKey] = fieldName.split(".");
        const errorMessage = validateField(
          fieldName,
          fields.attributes[childKey]
        );
        if (errorMessage) newErrors[fieldName] = errorMessage;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit form

      const data = {
        firstName: fields.owner_firstname,
        lastName: fields.owner_lastname,
        email: fields.email,
        merchantEmail:parsedUserData.email
      };
      dispatch(addClient({ data, token }));
      props.setUpdateButton(true);
      props.setShowModalUpdate(false);
    }
  };

  const handleClose = () => props.setShowModalUpdate(false);
  return (
    <div>
      <Modal
        show={props.showModalUpdate}
        onHide={handleClose}
        centered
        contentClassName="w-75 m-5"
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between pb-3">
              <div className="update-watch-form-heading">Add a Client</div>
              <button
                type="button"
                className="btn-close p-1"
                onClick={handleClose}
                style={{
                  backgroundColor: "#E2E2E2",
                  borderRadius: "200px",
                }}
              ></button>
            </div>

            <div className="d-sm-flex flex-row">
              <div className="d-flex flex-column me-sm-4">
                <span className="update-watch-form-text">Owner First Name</span>
                <input
                  type="text"
                  name="owner_firstname"
                  value={fields.owner_firstname}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.owner_firstname && (
                  <span className="error-field">{errors.owner_firstname}</span>
                )}
              </div>

              <div className="d-flex flex-column">
                <span className="update-watch-form-text">Owner Last Name</span>
                <input
                  type="text"
                  name="owner_lastname"
                  value={fields.owner_lastname}
                  onChange={handleInputChange}
                  className="input-box mb-2 p-1"
                />
                {errors.owner_lastname && (
                  <span className="error-field">{errors.owner_lastname}</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className="update-watch-form-text">Email</span>
              <input
                type="text"
                name="email"
                value={fields.email}
                onChange={handleInputChange}
                className="input-box mb-2 p-1"
              />
              {errors.email && (
                <span className="error-field">{errors.email}</span>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="update-watch-btn pt-2 pb-2 ps-4 pe-3"
              >
                Add <img src={updateIcon} alt="icon" className="ps-1" />
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddUpdateClient;
