// PrivateRoute.js
// eslint-disable-next-line no-unused-vars
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "./store/verifyTokenSlice";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const { data: checkToken } = useSelector((state) => state.token);

  useEffect(() => {
    dispatch(verifyToken(token));
  }, []);

  if (!token) {
    return <Navigate to="/" replace />;
  }
  if (checkToken === false) {
    return <Navigate to="/" replace />;
  }
  return children;
}
export default PrivateRoute;
