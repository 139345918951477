import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteWatch } from "../../../store/deleteWatchSlice";
import Modal from "react-bootstrap/Modal";
import yesIcon from "../../../assets/images/yes-icon.svg";

import "../delete/delete.css";

function Delete(props) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  async function handleDelete() {
    for (let i = 0; i < props.checkedList.length; i++) {
      let _id = props.watchesData.watches
        .filter((item) => item._id === props.checkedList[i])
        .map((item) => item.serialNumber);
      let res = await dispatch(deleteWatch({ _id, token }));
    }
    props.setCheckedList([]);
    props.setCheckedAll(false);
    props.setDeleteButton(true);
    props.setShowModalDeleteAll(false);
  }
  
  const handleClose = () => props.setShowModalDeleteAll(false);
  return (
    <div>
      <Modal
        show={props.showModalDeleteAll}
        onHide={handleClose}
        centered
        contentClassName="m-sm-5"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center pb-3 pt-3">
            <div className="d-flex text-center delete-watch-form-heading col-8">
              Are you sure you want to delete the selected items?
            </div>
          </div>

          <div className="d-flex justify-content-center p-2">
            <button
              onClick={handleDelete}
              className="delete-watch-btn pt-2 pb-2 ps-4 pe-3 me-2"
            >
              YES <img src={yesIcon} alt="" className="ms-2" />
            </button>
            <button
              onClick={handleClose}
              className="delete-watch-btn2 pt-2 pb-2 ps-4 pe-4"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Delete;
