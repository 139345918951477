import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStats } from "../../../store/statSlice";
import watchIcon from "../../../assets/images/dash4.svg";
import peopleIcon from "../../../assets/images/dash6.svg";
import imageIcon from "../../../assets/images/dash7.svg";
import checkIcon from "../../../assets/images/dash5.svg";
import dash1 from "../../../assets/images/dash1.svg";
import dash2 from "../../../assets/images/dash2.svg";
import dash3 from "../../../assets/images/dash3.svg";
import addIcon from "../../../assets/images/Add_round.svg";
import nextIcon from "../../../assets/images/next.svg";

function Statistics() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const { data: statsData, status } = useSelector((state) => state.stats);

  useEffect(() => {
    dispatch(fetchStats(token));
  }, []);

  return (
    <div>
      <div className=" d-sm-flex justify-content-between">
        <div className="d-flex col-2-5 flex-row statistics-box p-3 ">
          <div className="d-flex align-items-center pe-3">
            <img src={watchIcon} alt="watch-icon" className="watch-icon p-2" />
          </div>
          <div>
            <div className="statistics-heading-text">TOTAL WATCHES</div>
            <div className="statistics-numbers-text ">
         
              {statsData.totalWatches}
            </div>
          </div>
        </div>
        <div className="d-flex col-2-5  flex-row statistics-box p-3">
          <div className="d-flex align-items-center pe-3">
            <img src={checkIcon} alt="check-icon" className="watch-icon p-2" />
          </div>
          <div>
            <div className="statistics-heading-text">CERTIFIED WATCHES</div>
            <div className="statistics-numbers-text ">
              {statsData.certifiedWatches}
              {/* {22} */}
            </div>
          </div>
        </div>
        <div className="d-flex col-2-5  flex-row statistics-box p-3">
          <div className="d-flex align-items-center pe-3">
            <img
              src={peopleIcon}
              alt="people-icon"
              className="watch-icon p-2"
            />
          </div>
          <div>
            <div className="statistics-heading-text">TOTAL CLIENTS</div>
            <div className="statistics-numbers-text ">
              {statsData.totalClients}
              {/* {20} */}
            </div>
          </div>
        </div>
        <div className="d-flex col-2-5  flex-row statistics-box p-3">
          <div className="d-flex align-items-center pe-3">
            <img src={imageIcon} alt="image-icon" className="watch-icon p-2" />
          </div>
          <div>
            <div className="statistics-heading-text">TOTAL TRANSFERS</div>
            <div className="statistics-numbers-text ">
              {statsData.totalTransfers}
              {/* {55} */}
            </div>
          </div>
        </div>
      </div>

      <div className="d-sm-flex justify-content-around pt-4">
        <div className="statistics-card ps-1 pe-1 pt-2 pb-2">
          <div className="">
            <img src={dash3} alt="icon" />
          </div>
          <div>
            <div className="statistics-card-text">Have a New Watch?</div>
            <div className="statistics-card-text2 pb-2">
              Lorem ipsum dolor sit amet consectetur
            </div>
            <button
              onClick={() => {
                navigate("/watch");
              }}
              className="statistics-card-btn"
            >
              Add a Watch
              <img src={addIcon} alt="icon" className="" />
            </button>
          </div>
        </div>
        <div className="statistics-card ps-1 pe-1 pt-2 pb-2">
          <div className="me-2">
            <img src={dash2} alt="icon" className="pe-3" />
          </div>
          <div>
            <div className="statistics-card-text">Got a New Client?</div>
            <div className="statistics-card-text2 pb-2">
              Lorem ipsum dolor sit amet consectetur
            </div>
            <button
              className="statistics-card-btn"
              onClick={() => {
                navigate("/clients");
              }}
            >
              Add a Client <img src={addIcon} alt="icon" />
            </button>
          </div>
        </div>
        <div className="statistics-card ps-1 pe-1 pt-2 pb-2">
          <div className="me-2">
            <img src={dash1} alt="icon" className="pe-3" />
          </div>
          <div>
            <div className="statistics-card-text">Certify a Watch</div>
            <div className="statistics-card-text2 pb-2">
              Lorem ipsum dolor sit amet consectetur
            </div>
            <button
              className="statistics-card-btn"
              onClick={() => {
                navigate("/watch");
              }}
            >
              Go to Watches <img src={nextIcon} alt="icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
