import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
let data;
const loginSlice = createSlice({
  name: "login",
  initialState: {
    data,
    status: STATUSES.IDLE,
  },
  reducers: {
    setLogin(state, action) {
      console.log(action.payload);
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setLogin, setStatus } = loginSlice.actions;
export default loginSlice.reducer;

// Thunks
export const login = createAsyncThunk("login/verify", async (data) => {
  try {
    const response = await axios.post("auth/login", data);
    console.log(response.data);
    if (!response.data.success) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Login Failed. Incorrect email or password.",
        confirmButtonColor: "#4182EB",
      });

      // Redirect the user to the protected page
    }
    return response.data;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Login Failed. Something went wrong!!!",
      confirmButtonColor: "#4182EB",
    });
  }
});
