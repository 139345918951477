import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const maintainanceSlice = createSlice({
  name: "maintainance",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setMaintainance(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },

//   extraReducers: (builder) => {
//     builder
//       .addCase(updateWatch.pending, (state, action) => {
//         state.status = STATUSES.LOADING;
//       })
//       .addCase(updateWatch.fulfilled, (state, action) => {
//         state.data = action.payload;
//         state.status = STATUSES.IDLE;
//       })
//       .addCase(updateWatch.rejected, (state, action) => {
//         state.status = STATUSES.ERROR;
//       });
//   },
});

export const { setMaintainance, setStatus } = maintainanceSlice.actions;
export default maintainanceSlice.reducer;

// // Thunks
// export const fetchWatches = createAsyncThunk("watches/fetch", async (data) => {
//     const filter = data.pageStatus;
//     const page = data.page;
//     const search = data.searchInput;
  
//     const config = {
//       headers: { Authorization: "Bearer " + data.token },
//     };
//     const response = await axios.get(
//       `watch/get-all-watches/${filter}/${page}/${search}`,
//       config
//     );
//     const res = await response.data;
//     return res;
//   });