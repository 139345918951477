import React, { useEffect, useState } from 'react';
import "./mantainance.css"

const ReverseTimer = ({ expirationTime }) => {
  const calculateTimeLeft = () => {
    const difference = expirationTime - Date.now();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length ? (
        <span className='otp'>OTP will expire in: {timerComponents}</span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReverseTimer;
