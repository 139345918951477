import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Graph from "./Graph";

function Analytics() {
  const [key, setKey] = useState("All Watches");

  return (
    <div
      className="pb-4 pt-1"
      style={{ background: "#FFFFFF", borderRadius: "5px" }}
    >
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="m-1 p-1"
        style={{
          background: "#E2E2E2",
          borderRadius: "200px",
          width: "fit-content",
        }}
      >
        <Tab eventKey="All Watches" title="All Watches">
          <Graph status={key} />
        </Tab>
        <Tab eventKey="Certified Watches" title="Certified Watches">
          <Graph status={key} />
        </Tab>
        <Tab eventKey="All Clients" title="All Clients">
          <Graph status={key} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Analytics;
