import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const verifyOTPSlice = createSlice({
  name: "otp",
  initialState: {
    data: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    setToken(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOTP.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setToken, setStatus } = verifyOTPSlice.actions;
export default verifyOTPSlice.reducer;

// Thunks
export const verifyOTP = createAsyncThunk("otp/verify", async (data) => {
  const response = await axios.post(`auth/verify-otp`, data).catch((error) => {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "OTP Verificiation Failed.Something went wrong!!!",
      confirmButtonColor: "#4182EB",
    });
  });

  if (response.data.status) {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "OTP Verified.",
      confirmButtonColor: "#4182EB",
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "OTP Verificiation Failed.",
      confirmButtonColor: "#4182EB",
    });

    // Redirect the user to the protected page
  }
  console.log(response);
  return response.data;
});
