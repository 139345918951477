import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import Analytics from "./Analytics/analytics";
import Header from "./Header/header";
import Statistics from "./Statistics/statistics";
import TransferWatches from "./High Transfer/transfer-watches";
import Tabs from "../Tabs/tabs";
import "./dashboard.css";

function Dashboard() {
  const { theme } = useSelector((store) => store.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);
  return (
    <div className="d-flex flex-row">
      <Tabs />

      <div className="w-100">
        <Header />
        <div className={`container-fluid bg-${theme} main-layout`}>
          <p className={`dashboard-heading-${theme}  pt-2`}>Statistics</p>
          <Statistics />

          <div className="row pb-5">
            <div className="col-lg-7 col-12">
              <p className={`dashboard-heading-${theme} pt-3`}>Analytics</p>
              <Analytics />
            </div>
            <div className="col-lg-5 col-12">
              <p className={`dashboard-heading-${theme} pt-3`}>
                Watches with Highest Transfers
              </p>
              <TransferWatches />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
