import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
let data;
const forgetPasswordSlice = createSlice({
  name: "forgetPass",
  initialState: {
    data,
    status: STATUSES.IDLE,
  },
  reducers: {
    setPassword(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setPassword, setStatus } = forgetPasswordSlice.actions;
export default forgetPasswordSlice.reducer;

// Thunks
export const forgetPassword = createAsyncThunk(
  "reset/forget-password",
  async (data) => {
    const response = await axios
      .post(`auth/forget-password`, data)
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!!!",
          confirmButtonColor: "#4182EB",
        });
      });

    console.log(response.data, "res");
    if (response.data.success) {
      Swal.fire({
        title: "Password Reset",
        text: "Link send to your mail.Check your mail!",
        confirmButtonColor: "#4182EB",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${response.data.msg}`,
        confirmButtonColor: "#4182EB",
      });

      // Redirect the user to the protected page
    }

    return response.data;
  }
);
