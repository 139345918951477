import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCertificate } from "../../../store/addCertificateSlice";
import watchIcon from "../../../assets/images/watch-icon-2.svg";
import yesIcon from "../../../assets/images/yes-icon.svg";
import transferIcon from "../../../assets/images/transfer-icon.svg";
import Swal from "sweetalert2";
import "./transfer.css";
import { imageURL } from "../../../axios";

function Transfer(props) {
  const dispatch = useDispatch();
  // const { status } = useSelector((state) => state.certificate);
  const token = localStorage.getItem("token");

  const [errorFirstName, setErrorFirstName] = useState();
  const [errorLastName, setErrorLastName] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [errorWalletAddress, setErrorWalletAddress] = useState();
  const [firstName, setFirstName] = useState(props.data?.clientFirstName);
  const [lastName, setLastName] = useState(props.data?.clientLastName);
  const [email, setEmail] = useState(props.data?.email);
  const [customerAddress, setCustomerAddress] = useState("");
  console.log(props.data);
  const [metadata, setMetadata] = useState({
    image: props.data?.image,
    imageURI: props.data?.imageURI,
    name: props.data?.watchName,
    case_finishes: props.data?.attributes.caseFinishes,
    case_height: props.data?.attributes.caseHeight,
    case_strap: props.data?.attributes.caseStrap,
    case_screwed_in_lugs: props.data?.attributes.caseScrewedInLugs,
    case_diameter: props.data?.attributes.caseDiameter,
    case_waterproofing: props.data?.attributes.caseWaterProofing,
    case_clasp: props.data?.attributes.caseClasp,
    case_back: props.data?.attributes.caseBack,
    dial_glass: props.data?.attributes.dialGlass,
    dial_center: props.data?.attributes.dialCenterDial,
    dial_extrerior_elevation: props.data?.attributes.dialExteriorElevation,
    dial_complication: props.data?.attributes.dialComplication,
    dial_hands: props.data?.attributes.dialHands,
    crown_info: props.data?.attributes.crownInfo,
    crown_material: props.data?.attributes.crownMaterial,
    movement_automatic: props.data?.attributes.movementAutomatic,
    movement_access: props.data?.attributes.movementAccess,
    guarantee_purchase_date: props.data?.attributes.guaranteePurchaseDate,
    guarantee_purchase_time: props.data?.attributes.guaranteePurchaseTime,
    guarantee_statement: props.data?.attributes.guaranteeStatement,
    owner_firstname: props.data?.clientFirstName,
    owner_lastname: props.data?.clientLastName,
    owner_streetname: "Chemin des prés",
    owner_streetno: "Nr 2",
    owner_zipcode: "1200",
    owner_city: "Genève",
    owner_country: "Switzerland",
    price: props.data?.price,
    // pricing_name: "Timeless watch model white : HMS-001 ",
    // pricing_price: "$1,450.00",
    // pricing_qty: "1",
    // pricing_subtotal: "$1,550.00",
    // pricing_discount: "$0.00",
    // pricing_tax: "$400.00",
    // pricing_total: "$1,950.00",
  });
  useEffect(() => {
    setFirstName(props.data?.clientFirstName);
    setLastName(props.data?.clientLastName);
    setEmail(props.data?.email);
    setMetadata({
      image: props.data?.image,
      imageURI: props.data?.imageURI,
      name: props.data?.watchName,
      case_finishes: props.data?.attributes.caseFinishes,
      case_height: props.data?.attributes.caseHeight,
      case_strap: props.data?.attributes.caseStrap,
      case_screwed_in_lugs: props.data?.attributes.caseScrewedInLugs,
      case_diameter: props.data?.attributes.caseDiameter,
      case_waterproofing: props.data?.attributes.caseWaterProofing,
      case_clasp: props.data?.attributes.caseClasp,
      case_back: props.data?.attributes.caseBack,
      dial_glass: props.data?.attributes.dialGlass,
      dial_center: props.data?.attributes.dialCenterDial,
      dial_extrerior_elevation: props.data?.attributes.dialExteriorElevation,
      dial_complication: props.data?.attributes.dialComplication,
      dial_hands: props.data?.attributes.dialHands,
      crown_info: props.data?.attributes.crownInfo,
      crown_material: props.data?.attributes.crownMaterial,
      movement_automatic: props.data?.attributes.movementAutomatic,
      movement_access: props.data?.attributes.movementAccess,
      guarantee_purchase_date: props.data?.attributes.guaranteePurchaseDate,
      guarantee_purchase_time: props.data?.attributes.guaranteePurchaseTime,
      guarantee_statement: props.data?.attributes.guaranteeStatement,
      owner_firstname: props.data?.clientFirstName,
      owner_lastname: props.data?.clientLastName,
      owner_streetname: "Chemin des prés",
      owner_streetno: "Nr 2",
      owner_zipcode: "1200",
      owner_city: "Genève",
      owner_country: "Switzerland",
      price: props.data?.price,
      // pricing_name: "Timeless watch model white : HMS-001 ",
      // pricing_price: "$1,450.00",
      // pricing_qty: "1",
      // pricing_subtotal: "$1,550.00",
      // pricing_discount: "$0.00",
      // pricing_tax: "$400.00",
      // pricing_total: "$1,950.00",
    });
  }, [props]);

  async function handleSubmit() {
    props.setIsLoading(true);

    let watchId = props.data?._id;
    let clientId = props.data?.clientId;
    dispatch(
      addCertificate({
        firstName,
        lastName,
        customerAddress,
        watchId,
        clientId,
        email,
        metadata,
        token,
      })
    )
      .unwrap()
      .then((res) => {
        // handle result here
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Watch Certified successfully.",
            confirmButtonColor: "#4182EB",
          });
          setEmail(props.data?.email);
          setFirstName(props.data?.clientFirstName);
          setLastName(props.data?.clientLastName);
          setCustomerAddress("");
          props.setIsLoading(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Watch not certified.",
            confirmButtonColor: "#4182EB",
          });
          setEmail(props.data?.email);
          setFirstName(props.data?.clientFirstName);
          setLastName(props.data?.clientLastName);
          setCustomerAddress("");
          props.setIsLoading(false);
        }
      })
      .catch((err) => {
        // handle error here
        console.log(err);
        setEmail(props.data?.email);
        setFirstName(props.data?.clientFirstName);
        setLastName(props.data?.clientLastName);
        setCustomerAddress("");
        props.setIsLoading(false);
      });
  }

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ width: "max-content" }}>
            {/* <div className="modal-header"></div> */}
            <div className="modal-body">
              <div className="d-flex justify-content-center pb-3 pt-2">
                <div className="d-flex text-center transfer-watch-form-heading col-md-8">
                  Are you sure, you want to add a certificate to following item?
                </div>
              </div>
              <div
                className="ms-2 me-2"
                style={{ border: "1px solid #E2E2E2", borderRadius: "120px" }}
              ></div>
              <div className="d-flex align-items-center pt-3 pb-3 ms-3">
                <img
                  src={`${imageURL}uploads/` + props.data?.image}
                  alt="icon"
                  style={{ maxHeight: "50px", maxWidth: "60px" }}
                  className="pe-3 "
                />

                <div>
                  <div className="transfer-watch-form-text">
                    {props.data?.watchName}
                  </div>
                  <div className="d-flex transfer-watch-form-text2">
                    {props.data?.clientFirstName +
                      ` ` +
                      props.data?.clientLastName}
                    <div className="d-flex align-items-center transfer-watch-form-text3 ps-2 pe-2 mt-1">
                      owner
                    </div>
                  </div>
                </div>
              </div>
              {props.data?.clientId === null ? (
                <>
                  <div className="d-md-flex">
                    <div className="d-flex flex-column ps-3 pb-3 pe-md-5 pe-3">
                      <span className="add-watch-form-text">First Name</span>
                      <input
                        onChange={(e) => {
                          const pattern = /^[a-zA-Z].*/;
                          if (!e.target.value) {
                            setErrorFirstName("First name is required.");
                            setFirstName(e.target.value);
                          } else if (!pattern.test(e.target.value))
                            setErrorFirstName("Input is not valid.");
                          else {
                            setErrorFirstName();
                            setFirstName(e.target.value);
                          }
                        }}
                        value={firstName}
                        type="text"
                        className="input-box p-1"
                      />
                      {errorFirstName && (
                        <span className="error-field">{errorFirstName}</span>
                      )}
                    </div>

                    <div className="d-flex flex-column ms-md-5  ps-3 pb-3 pe-md-0 pe-3">
                      <span className="add-watch-form-text">Last Name</span>
                      <input
                        onChange={(e) => {
                          const pattern = /^[a-zA-Z].*/;
                          if (!e.target.value) {
                            setErrorLastName("Last name is required.");
                            setLastName(e.target.value);
                          } else if (!pattern.test(e.target.value))
                            setErrorLastName("Input is not valid.");
                          else {
                            setErrorLastName();
                            setLastName(e.target.value);
                          }
                        }}
                        value={lastName}
                        type="text"
                        className="input-box p-1"
                      />
                      {errorLastName && (
                        <span className="error-field">{errorLastName}</span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column ps-3 pb-3 pe-3">
                    <span className="add-watch-form-text">Email</span>
                    <input
                      onChange={(e) => {
                        if (!e.target.value) {
                          setErrorEmail("Email is required.");
                          setEmail(e.target.value);
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            e.target.value
                          )
                        ) {
                          setEmail(e.target.value);
                          setErrorEmail("Email is not valid.");
                        } else {
                          setErrorEmail();
                          setEmail(e.target.value);
                        }
                      }}
                      value={email}
                      type="text"
                      className="input-box p-1"
                    />
                    {errorEmail && (
                      <span className="error-field">{errorEmail}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <div className="d-flex flex-column ps-3 pb-3 pe-md-5 pe-3">
                      <span className="add-watch-form-text">First Name</span>
                      <input
                        value={firstName}
                        type="text"
                        className="input-box p-1"
                        readOnly
                      />
                    </div>
                    <div className="d-flex flex-column ms-md-5  ps-3 pb-3 pe-md-0 pe-3">
                      <span className="add-watch-form-text">Last Name</span>
                      <input
                        value={lastName}
                        type="text"
                        className="input-box p-1"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column ps-3 pb-3 pe-3">
                    <span className="add-watch-form-text">Email</span>
                    <input
                      value={email}
                      type="text"
                      className="input-box p-1"
                      readOnly
                    />
                  </div>
                </>
              )}
              <div className="d-flex flex-column ps-3 pb-3 pe-3">
                <span className="add-watch-form-text">Wallet Address</span>
                <input
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrorWalletAddress("Wallet Address is required.");
                      setCustomerAddress(e.target.value);
                    } else {
                      setErrorWalletAddress();
                      setCustomerAddress(e.target.value);
                    }
                  }}
                  value={customerAddress}
                  type="text"
                  className="input-box p-1"
                />
                {errorWalletAddress && (
                  <span className="error-field">{errorWalletAddress}</span>
                )}
              </div>

              <div className="d-flex justify-content-center p-2">
                {errorEmail ||
                errorFirstName ||
                errorLastName ||
                errorWalletAddress ||
                !email ||
                !firstName ||
                !lastName ||
                !customerAddress ||
                email === "" ||
                firstName === "" ||
                lastName === "" ||
                customerAddress === "" ? (
                  <button
                    className="transfer-watch-btn pt-2 pb-2 ps-4 pe-3 me-2"
                    onClick={() => {
                      const pattern = /^[a-zA-Z].*/;
                      if (!firstName)
                        setErrorFirstName("First name is required.");
                      if (!lastName) setErrorLastName("Last name is required.");
                      if (!email) setErrorEmail("Email is required.");
                      if (!customerAddress)
                        setErrorWalletAddress("Wallet Address is required.");
                    }}
                  >
                    YES <img src={yesIcon} alt="" className="ms-2" />
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    data-bs-dismiss="modal"
                    className="transfer-watch-btn pt-2 pb-2 ps-4 pe-3 me-2"
                  >
                    YES <img src={yesIcon} alt="" className="ms-2" />
                  </button>
                )}
                <button
                  onClick={() => {
                    setErrorEmail();
                    setErrorFirstName();
                    setErrorWalletAddress();
                    setErrorLastName();
                    setEmail(props.data?.email);
                    setFirstName(props.data?.clientFirstName);
                    setLastName(props.data?.clientLastName);
                    setCustomerAddress("");
                  }}
                  data-bs-dismiss="modal"
                  className="transfer-watch-btn2 pt-2 pb-2 ps-4 pe-4"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer"> </div> */}
        </div>
      </div>
    </>
  );
}

export default Transfer;
