import axios from "../axios.js";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const deleteClientSlice = createSlice({
  name: "client",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setClients(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteClient.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setClients, setStatus } = deleteClientSlice.actions;
export default deleteClientSlice.reducer;

// Thunks
export const deleteClient = createAsyncThunk("client/delete", async (data) => {
const body={
  email:data.clientEmail
}
  const config = {
    headers: { Authorization: "Bearer " + data.token },
    data: { email: data.clientEmail }, // `data` is used to send the request body
  };
  const response = await axios
    .delete(`client/delete-client` , 
     config)
    .catch((error) => {
      console.log(error);
    });
  console.log(response.data);

  return response.data;
});
