import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { login } from "../../store/loginSlice";
import { STATUSES } from "../../store/loginSlice";
import { forgetPassword } from "../../store/forgetPasswordSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {LOGIN_SCREEN_TEXT} from "../../constant"
import {logo} from "../../constant"
// import logo from "../../assets/images/logo-icon.svg";
// import logo from "../../assets/images/dwiss-icon.svg";


// diwss logo
// import logo from "../../assets/images/dwiss new logo.png";

//timeless logo
// import logo from "../../assets/logos/timeless-logo.png";

import Swal from "sweetalert2";

import "./login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const { theme } = useSelector((store) => store.theme);
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.login);

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  async function handleLogin(email, password) {
    if (email && password) {
      let res = await dispatch(login({ email, password })).unwrap();
      //console.log(res);
      if (res.success && res.merchant.is2Factor) {
        localStorage.setItem(
          "userData",
          JSON.stringify({
            merchant: res.merchant,
            token: res.token,
          })
        );
        navigate("/authenticate");
      } else if (res.success && !res.merchant.is2Factor) {
        localStorage.setItem("token", res.token);
        localStorage.setItem("userData", JSON.stringify(res.merchant));
        navigate("/dashboard");
      }
    }
  }

  async function handleReset(email) {
    if (email) {
      let res = await dispatch(forgetPassword({ email })).unwrap();
      // if (res.success) {
      //   return <Forget value={email} />;
      // }

      // if (res.success !== false) navigate(`/forget-password/${res.token}`);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your email address.",
        confirmButtonColor: "#4182EB",
      });
    }
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address format";
    }

    return error;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    } else if (value.length < 8) {
      error = "Password must be 8 characters at minimum";
    }

    return error;
  }
  function togglePassword() {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  }
  function onSubmit(values) {
    setEmail(values.email);
    setPassword(values.password);
    handleLogin(values.email, values.password);
  }

  return (
    <div
      className={`d-flex justify-content-center align-items-center login-${theme}`}
    >
      <div
        className="d-flex flex-column p-5"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E2E2E2",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex justify-content-center pt-2 pb-3">
          <img src={logo} alt="logo"  style={{height:"8vh"}} />
        </div>
        {/* <div className="login-heading pb-1">Timeless Digital passport CRM</div> */}
        <div className="login-heading pb-1">{LOGIN_SCREEN_TEXT}</div>
        <div className="login-heading2 pb-4">
          Enter your details to sign in to your account
        </div>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values }) => (
            <Form>
              <div className="form-group pb-3">
                <Field
                  name="email"
                  placeholder="Email"
                  className={`form-control ${
                    touched.email && errors.email
                      ? "sign-in-inp-error is-invalid"
                      : "sign-in-inp"
                  }`}
                  validate={validateEmail}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
              </div>

              <div className=" form-group position-relative pb-3">
                <Field
                  name="password"
                  type={passwordType}
                  placeholder="Password"
                  className={`form-control ${
                    touched.password && errors.password
                      ? "sign-in-inp-error is-invalid"
                      : "sign-in-inp"
                  }`}
                  validate={validatePassword}
                />

                <button
                  type="button"
                  className={`
                  ${
                    touched.password &&
                    touched.email &&
                    errors.password &&
                    errors.email
                      ? "show-btn-error1"
                      : touched.password && errors.password
                      ? "show-btn-error"
                      : touched.email && errors.email
                      ? "show-btn-error2"
                      : "show-btn"
                  } `}
                  onClick={togglePassword}
                >
                  Show
                </button>
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
              </div>

              <button className="sign-in-btn w-100 p-1" type="submit">
                {status === STATUSES.LOADING ? (
                  <div
                    className="spinner-border spinner-border-sm text-light m-1"
                    role="status"
                  ></div>
                ) : (
                  <div className="sign-in-btn d-flex justify-content-center p-1">
                    SIGN IN
                  </div>
                )}
              </button>

              <div
                onClick={(e) => {
                  setEmail(values.email);
                  handleReset(values.email);
                }}
                className="login-text2 pt-2"
                style={{ cursor: "pointer" }}
              >
                Forgot your password?
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
