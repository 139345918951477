import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const addWatchSlice = createSlice({
  name: "watch",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setWatches(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadWatch.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(uploadWatch.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(uploadWatch.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setWatches, setStatus } = addWatchSlice.actions;
export default addWatchSlice.reducer;

// Thunks
export const uploadWatch = createAsyncThunk("watch/upload", async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + data.token,
    },
  };
  const response = await axios.post("watch/add-watch", data.formData, config);

  //console.log(response.data);

  return response.data;
});


export const addNewWatch = createAsyncThunk("watch/upload", async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: "Bearer " + data.token,
    },
  };
  console.log("data.formData, api" , data,)
  const response = await axios.post("user-watches/add_watch", data.formData, config);

  //console.log(response.data);

  return response.data;
});
