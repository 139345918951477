import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  menu: false,
};

const menuBarSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    menuBar: (state) => {
      state.menu = !state.menu;
    },
  },
});

export const { menuBar } = menuBarSlice.actions;

export default menuBarSlice.reducer;
