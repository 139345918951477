import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const statSlice = createSlice({
  name: "stats",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setStats(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStats.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setStats, setStatus } = statSlice.actions;
export default statSlice.reducer;

// Thunks
export const fetchStats = createAsyncThunk("stats/fetch", async (token) => {
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  // const response = await axios.get(`analytics/stats`, config); //old end point 
  const response = await axios.get(`watch/statistics`, config); // updated endpoint 
  const data = await response.data.data;
  return data;
});
