import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { resetPassword } from "../../store/updatePassword";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../assets/images/logo-icon.svg";
import signIn from "../../assets/images/Sign_in_circle.svg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "./forget.css";

function Forget(props) {
  const params = useParams();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const { theme } = useSelector((store) => store.theme);
  const [emailCheck, setEmailCheck] = useState(props.value);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address format";
    }

    return error;
  }

  function validatePassword(value) {
    let error;
    if (!value) {
      error = "Password is required";
    } else if (value.length < 8) {
      error = "Password must be 8 characters at minimum";
    }

    return error;
  }
  function onSubmit(values) {
    setEmail(values.email);
    setPass(values.password);
    setConfirmPass(values.confirmPassword);
    handleReset(values.email, values.password, values.confirmPassword);
  }

  async function handleReset(email, password, confirmPass) {
    // if (emailCheck !== email) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Email is not correct!!",
    //     confirmButtonColor: "#4182EB",
    //   });
    // } else
    if (password === confirmPass) {
      const res = await dispatch(resetPassword({ email, password })).unwrap();
      //console.log(res, "output");
      if (res.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Password updated successfully.",
          confirmButtonColor: "#4182EB",
        });
        navigate("/");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password not updated. Something went wrong!!",
          confirmButtonColor: "#4182EB",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Passwords not matching!!",
        confirmButtonColor: "#4182EB",
      });
    }
  }
  return (
    <div
      className={`d-flex justify-content-center align-items-center login-${theme}`}
    >
      <div
        className="d-flex flex-column p-5"
        style={{ backgroundColor: "#fff", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-center p-3">
          <img src={logo} alt="logo" />
        </div>
        <div className="forget-heading p-2 mb-3">Reset Password</div>

        <Formik
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values }) => (
            <Form>
              <div className="form-group pb-2">
                <Field
                  name="email"
                  placeholder="Email"
                  className={`form-control ${
                    touched.email && errors.email
                      ? "sign-in-inp-error is-invalid"
                      : "sign-in-inp"
                  }`}
                  validate={validateEmail}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group pb-2">
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={`form-control ${
                    touched.password && errors.password
                      ? "sign-in-inp-error is-invalid"
                      : "sign-in-inp"
                  }`}
                  validate={validatePassword}
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <Field
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  className={`form-control ${
                    touched.confirmPassword && errors.confirmPassword
                      ? "sign-in-inp-error is-invalid"
                      : "sign-in-inp"
                  }`}
                  validate={validatePassword}
                />
                <ErrorMessage
                  component="div"
                  name="confirmPassword"
                  className="invalid-feedback"
                />
              </div>
              <div className="d-flex justify-content-center pt-3">
                <button
                  className="sign-in-btn d-flex text-center ps-4 pe-3 pt-1 pb-1"
                  type="submit"
                >
                  RESET <img src={signIn} alt="icon" className="ps-1" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Forget;
