import "./mantainance.css";
import React, { useState } from "react";

const UpdateAttributeForm = ({
  watch,
  attribute,
  onChange,
  errors,
  readOnly,
}) => {
  const [value, setValue] = useState(watch.attributes[attribute]);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(attribute, newValue);
  };

  return (
    <div className="d-flex flex-column flex-grow-1  position-relative">
      <span className="update-watch-form-text">{attribute}</span>
      <input
        type="text"
        name={attribute}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
        style={{
          backgroundColor: readOnly ? "#bfcfe8" : "default",
         pointerEvents: readOnly ? "auto" : "default",
         cursor: readOnly ? "pointer" : "auto", // Set cursor to "pointer" when readOnly is true
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}

        className="input-box mb-2 p-1"
      />
          {( readOnly && showTooltip &&
        <div className="tooltip">{value}</div>
      )}
      {errors[attribute] && (
        <span className="error-field">{errors[attribute]}</span>
      )}
      {/* </div> */}
    </div>
  );
};

export default UpdateAttributeForm;
