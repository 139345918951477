import React from "react";
import ".././mantainance.css";

export const DetailsTable = ({ transactions }) => {
  const columns = transactions.length > 0 ? Object.keys(transactions[0]) : [];
  console.log("🚀 ~ table ~ columns:", columns, "transactions", transactions);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
    return date.toLocaleDateString(); // Get the formatted date string
  };

  return (
    <div
      className="transaction-table-container-1"
      style={{ backgroundColor: "#fff", borderRadius: "4px" }}
    >
      <table className="transaction-table">
        <thead
          style={{
            fontFamily: "Poppins",
            fontWeight: 550,
            fontSize: "12px",
            // lineHeight: "12px",
            color: "#414141",
            // padding: 0,
            // margin: 0,
          }}
        >
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="th-transaction-1" style={{
                backgroundColor:"#bfcfe8", lineHeight: "2", // Adjust this value to increase or decrease the cell height

              }}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="details-page-text">
          {transactions.map((transaction, index) => (
            <tr key={index}>
              {columns.map((column, columnIndex) => (
                  <td
                      title={typeof transaction[column] === "object" ? null : column.toUpperCase() == "TIMESTAMP" ?
                          formatDate(transaction[column]) : transaction[column]}
                      key={columnIndex} style={{
                    minWidth: typeof transaction[column] === "object" ? '220px' : "100px",
                    maxWidth: "auto",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}>
                    {typeof transaction[column] === "object" ? (
                        <ul style={{textAlign: 'left', paddingLeft: '1'}}>
                          {Object.entries(transaction[column]).map(
                              ([key, value]) => (
                                  key != "_id" && (
                                      <li key={key} style={{listStyleType: 'number'}}>
                                        <strong>{key}: </strong> {value}
                                      </li>)
                              )
                          )}
                        </ul>
                    ) : column.toUpperCase() == "TIMESTAMP" ? (
                        formatDate(transaction[column])
                    ) : column.toUpperCase() == "HASH" ? (
                        <a
                            href={`https://www.oklink.com/amoy/tx/${transaction[column]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800 underline"
                        >
                          {transaction[column]}
                        </a>
                    ) : (
                        transaction[column]
                    )}
            </td>
          ))}
        </tr>
        ))}
      </tbody>
    </table>
</div>
)
  ;
};

{/* {transactions.map((row, index) => (
  <tr key={index}>
    {columns.map((column, columnIndex) => (
      <td key={columnIndex}>{column.toUpperCase()=="TIMESTAMP"? formatDate(row[column]) :row[column]}</td>
    ))}
  </tr>
))} */}