import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const detailClientSlice = createSlice({
  name: "detailClient",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setDetailClient(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetailClient.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchDetailClient.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchDetailClient.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setDetailClient, setStatus } = detailClientSlice.actions;
export default detailClientSlice.reducer;

// Thunks
export const fetchDetailClient = createAsyncThunk(
  "detail-client/fetch",
  async (data) => {
    const config = {
      headers: { Authorization: "Bearer " + data.token },
    };
    const response = await axios.get(`watch/get-watches/` + data.id, config);
    const res = await response.data;
    return res;
  }
);
