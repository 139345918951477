import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const clientsNameSlice = createSlice({
  name: "clientsName",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setClientsName(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsName.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchClientsName.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchClientsName.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setClientsName, setStatus } = clientsNameSlice.actions;
export default clientsNameSlice.reducer;

// Thunks
export const fetchClientsName = createAsyncThunk(
  "clientsName/fetch",
  async (token) => {
    const config = {
      headers: { Authorization: "Bearer " + token },
    };
    const response = await axios.get(`client/get-all-clients-name`, config);
    const res = await response.data;
    return res;
  }
);
