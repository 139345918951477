import axios from "../axios.js";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const watchSlice = createSlice({
  name: "client",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    setWatches(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setWatches, setStatus } = watchSlice.actions;
export default watchSlice.reducer;

// Thunks
export const fetchClients = createAsyncThunk("clients/fetch", async (data) => {
  const page = data.page;
  const search = data.searchInput;
  const sortConfig = data.sortConfig;
  const filter = data.filter;
  const config = {
    headers: { Authorization: "Bearer " + data.token },
  };

  const response = await axios.get(
    `client/get-client?page=${page}&search=${search}&pageSize=10&sortBy=${sortConfig.field}&sortOrder=${sortConfig.direction}&filter=${filter}`,
    config
  );
  const res = await response.data;
  return res;
});
