import axios from "axios";

export const baseURLClient= process.env.REACT_APP_BACKEND_URL; 

const instance = axios.create({
 
  baseURL:baseURLClient
});
  
export default instance;

// imageUrl
export const imageURL = process.env.REACT_APP_IMAGE_URL; 