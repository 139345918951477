import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const resetPasswordSlice = createSlice({
  name: "pass",
  initialState: {
    data: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    setPass(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setPass, setStatus } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;

// Thunks
export const resetPassword = createAsyncThunk(
  "password/reset",
  async (body) => {
    const response = await axios
      .post(`auth/reset-password`, body)
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!!!",
          confirmButtonColor: "#4182EB",
        });
      });
    const data = await response.data;

    return data;
  }
);
