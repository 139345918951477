import React, { useEffect, useState } from "react";
import Twiny from "./abis/Twiny.json";
import Web3 from "web3";
import { getNFTs } from "./getNfts";
import { Card } from "react-bootstrap";
import "./nfts.css";
import { useNavigate } from "react-router-dom";
import { WalletConnect } from "../WalletConnect/WalletConnect";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

const Nfts = () => {
  const navigate = useNavigate();
  const [web3, setWeb3] = useState(null);
  const [nfts, setNFTs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState(null);
  const { address } = useAccount();

  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const fetchNFTs = async (_WalletAddress) => {
    if (_WalletAddress) {
      setIsLoading(true);
      try {
        // Fetch NFTs owned by the connected wallet address
        const nftContractAddress = "0x0fD999944ff789313839FF9923E96100d338dAe7"; // Address of the NFT contract
        const nftTokens = await getNFTs(_WalletAddress, nftContractAddress);
        setNFTs(nftTokens);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch NFTs:", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (walletAddress) fetchNFTs(walletAddress);
  }, [walletAddress]);

  useEffect(() => {
    setWalletAddress(address);
    if (!address) setNFTs([]);
  }, [address]);

  function NFTCard(nft) {
    return (
      <div key={nft.tokenId} className="d-flex justify-content-center m-2 p-2">
        <div className="d-flex card-container p-2">
          <div className="me-3">
            <img
              src={nft.rawMetadata.image}
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="d-flex flex-column">
            <div className="nfts-heading">Token ID # {nft.tokenId}</div>
            <div className="nfts-heading">
              {" "}
              Title: <span className="nfts-text">{nft.rawMetadata.name}</span>
            </div>

            <div className="nfts-heading col-10">
              Description:{" "}
              <span className="nfts-text">{nft.rawMetadata.description}</span>
            </div>
            {/* <div className="nfts-heading">
              Metadata:{" "}
              <a
                href={`${nft.tokenUri.raw}`}
                className="nfts-text"
                target="_blank"
              >
                {nft.tokenUri.raw}
              </a>
            </div> */}
            <div>
              <button
                className="wallet-btn "
                onClick={() => {
                  navigate(`/Nft-Detail/${nft.tokenId}`);
                }}
              >
                Nft Detail
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Rest of the component code...
  // console.log("ethereum client",Array.from(window.ethereum.providerMap)[0].isMetaMask);

  return (
    <div className="container-fluid">
      {!walletAddress || walletAddress == null ? (
        <div
          className="d-flex justify-content-center align-items-center p-5"
          style={{ height: "100vh" }}
        >
          <div>
            <div className="main-heading-nft">
              Connect your wallet to show NFTs.
            </div>
            <div className="d-flex justify-content-center pt-2">
              <WalletConnect addressSetter={setWalletAddress} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center p-5">
              <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {walletAddress && nfts.length === 0 ? (
                <div
                  className="d-flex justify-content-center align-items-center p-5"
                  style={{ height: "100vh" }}
                >
                  <div className="main-heading-nft">No NFTs Found.</div>
                </div>
              ) : (
                <div className="pt-3">
                  <div className="d-flex justify-content-center main-heading-nft">
                    My NFTs
                  </div>
                  <div className="d-flex justify-content-center pt-2">
                    <button
                      className="wallet-btn "
                      onClick={() => {
                        disconnect();
                      }}
                    >
                      Disconnect Wallet
                    </button>
                  </div>
                  {walletAddress != null && nfts?.map((nft) => NFTCard(nft))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Nfts;
