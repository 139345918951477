import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuBar } from "../../store/menuBarSlice";
import Dropdown from "react-bootstrap/Dropdown";
import { imageURL } from "../../axios";

import menuIcon from "../../assets/images/menu.svg";
import user from "../../assets/images/person-icon.svg";
import Swal from "sweetalert2";
import "./logout.css";

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const { theme } = useSelector((store) => store.theme);

  const res = localStorage.getItem("userData");
  const data = JSON.parse(res);

  useEffect(() => {
    setFirstName(data.firstName);
  }, []);
  function menuHandler() {
    dispatch(menuBar());
  }
  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  }
  return (
    <div className="d-flex justify-content-end align-items-center">
      {/* {data.image ? (
        <img src={`${imageURL}users/` + data.image} alt="icon" />
      ) : (
        <img src={user} alt="icon" />
      )} */}
      <Dropdown>
        <Dropdown.Toggle
          // className="watch-dropdown3 me-2 p-1"
          className="watch-dropdown3 me-2"
          style={{ width: "auto", height: "auto", padding: '5px 10px' }} // Example values for px and py

          id="dropdown-basic"
        >
          {firstName[0]?.toUpperCase()}
          {firstName[1]?.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <img
        onClick={menuHandler}
        className="d-md-none d-sm-flex justify-content-center"
        src={menuIcon}
        alt="icon"
        style={{ width: "25%", cursor: "pointer" }}
      />
    </div>
  );
}

export default Logout;
