import { useEffect } from "react";
import { useAccount, useConnect } from "wagmi";

export function WalletConnect({ addressSetter, setCurrentProvider }) {
  console.log("setCurrentProvider", setCurrentProvider);
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const { address, connector, isConnected } = useAccount();
  useEffect(() => {
    if (address) addressSetter(address);
    if (address && setCurrentProvider != undefined)
      setCurrentProvider(connector?.name);
  }, [address]);
  console.log("connected connector", connector?.name);
  return (
    <div>
      {connectors.map((connector_) => (
        <button
          disabled={!connector_.ready}
          key={connector_.id}
          onClick={() => {
            connect({ connector: connector_ });
          }}
          style={{
            background: "#4182eb",
            borderRadius: "3px",
            border: "none",
            lineHeight: "18px",
            color: "#fff",
            margin: "10px 10px",
            padding: "7px 20px",
          }}
        >
          {connector_.name}
          {!connector_.ready && " (unsupported)"}
          {isLoading &&
            connector_.id === pendingConnector?.id &&
            " (connecting)"}
        </button>
      ))}

      {error && <div>{error.message}</div>}
    </div>
  );
}
