import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const resendOTPSlice = createSlice({
  name: "otp",
  initialState: {
    data: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    setToken(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendOTP.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setToken, setStatus } = resendOTPSlice.actions;
export default resendOTPSlice.reducer;

// Thunks
export const resendOTP = createAsyncThunk("otp/resend", async (data) => {
  const response = await axios.post(`auth/resend-otp`, data);
  const res = await response.data;

  if (response.data.success) {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "OTP send to your mail. Check your mail!!!",
      confirmButtonColor: "#4182EB",
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "OTP resending failed!!!",
      confirmButtonColor: "#4182EB",
    });
  }

  return res;
});
