import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { fetchDetailClient } from "../../store/detailClientSlice";
import { STATUSES } from "../../store/detailClientSlice";
import Tabs from "../Tabs/tabs";
import Logout from "../Logout/logout";
import back from "../../assets/images/back.svg";
import "./clientDetail.css";

function ClientDetail() {
  const params = useParams();
  const navigate = useNavigate();

  const id = params.id;

  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const { data: watchData, status } = useSelector(
    (state) => state.detailClient
  );

  
  useEffect(() => {
    dispatch(fetchDetailClient({ id, token }));
  }, [params.id]);

  const { theme } = useSelector((store) => store.theme);

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  function handleBackClick() {
    navigate("/clients");
  }

  const WatchList = (watches) => {
    return (
      <div className="p-2 row">
        {watches?.map((watch) => (
          <div
            key={watch._id}
            className="card client-card col-lg-5 col-11 ms-lg-5 ms-3 mt-1 mb-1 "
          >
            <div className="card-body">
              <p className="client-detail-heading">{watch.watchName}</p>
              <p className="client-detail-text mb-2 text-muted">
                {watch.brandName}
              </p>
              {watch.isCertified ? (
                <p className="client-detail-text">Certified Watch: Yes</p>
              ) : (
                <p className="client-detail-text">Certified Watch: NO</p>
              )}
              <p className="client-detail-text">Price: ${watch.price}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (status === STATUSES.ERROR) {
    return <div className="failed-text">Something went wrong!</div>;
  }
  return (
    <div className={`d-flex flex-row bg-${theme} main-layout2`}>
      <Tabs />
      <div className="w-100">
        <div
          className="d-flex justify-content-between ps-sm-3 pt-2 pb-2"
          style={{
            background: "#FFFFFF",
            border: "1px solid #E2E2E2",
            borderRadius: "5px",
          }}
        >
          <button
            onClick={handleBackClick}
            className="details-page-main-heading ps-sm-4"
            style={{ background: "none", border: "none" }}
          >
            <img src={back} alt="icon" className="pe-sm-3 pe-2 pb-1" />
            Client Watches List
          </button>
          <Logout />
        </div>
        <div className="container-fluid">
          {status === STATUSES.LOADING ? (
            <div className="d-flex justify-content-center align-items-center p-5">
              {theme === "light" ? (
                <div className={`spinner-border text-dark`} role="status"></div>
              ) : (
                <div
                  className={`spinner-border text-light`}
                  role="status"
                ></div>
              )}
            </div>
          ) : (
            <>
              {watchData?.watch && watchData?.watch.length > 0 ? (
                <>{WatchList(watchData?.watch)}</>
              ) : (
                <div
                  className={`d-flex justify-content-center details-container-heading-${theme} p-5`}
                >
                  No Watches Found
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientDetail;
