import React, { useState, useRef } from 'react';

const OTPCard = ({ onChange }) => {
  const [otpCode, setOtpCode] = useState(['', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (index, value) => {
    const newOtpCode = [...otpCode];
    newOtpCode[index] = value;
    setOtpCode(newOtpCode);

    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    }

    onChange(newOtpCode.join(''));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otpCode[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
<div>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    {otpCode.map((digit, index) => (
      <input
        key={index}
        type="text"
        name={`otp-${index}`}
        value={digit}
        maxLength={1}
        onChange={(e) => handleChange(index, e.target.value)}
        onKeyDown={(e) => handleKeyDown(e, index)}
        ref={inputRefs[index]}
        style={{ width: '100%', marginRight: '8px', textAlign: 'center' }}
        className="input-box p-1"
      />
    ))}
  </div>
</div>
  );
};

export default OTPCard;
