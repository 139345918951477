import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { verifyOTP } from "../../store/verifyOTPSlice";
import { resendOTP } from "../../store/resendOTPSlice";
import logo from "../../assets/images/logo-icon.svg";
import logo1 from "../../assets/images/logo-icon1.svg";
import authenticateIcon from "../../assets/images/authenticate-icon.svg";
import signIn from "../../assets/images/Sign_in_circle.svg";
import "./authenticate.css";

function Authenticate() {
  const navigate = useNavigate();
  const { theme } = useSelector((store) => store.theme);
  const dispatch = useDispatch();
  const res = localStorage.getItem("userData");
  const data = JSON.parse(res);
  const merchant = data.merchant;
  let email = merchant.email;
  if (email)
    email = email?.substring(0, 1) + "***" + email?.substring(4, email?.length);

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = async (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    // Move to the next input field
    if (e.target.value !== "") {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
        // All OTP digits have been entered
        // You can perform additional actions here
        // const otp1 = parseInt(
        //   otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5]
        // );
        // console.log(otp1);
        // const user_id = merchant._id;
        // const response = await dispatch(verifyOTP({ otp1, user_id })).unwrap();
        // setOtp(["", "", "", "", "", ""]);
        // if (response.status) {
        //   localStorage.setItem("token", data.token);
        //   localStorage.setItem("userData", JSON.stringify(merchant));
        //   navigate("/dashboard");
        // }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Move to the previous input field
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
    if (e.key === "Enter") {
      inputRefs.current[0].focus();
      handleSubmit();
    }
  };

  function handleResend() {
    const user_id = merchant._id;
    dispatch(resendOTP({ user_id }));
  }

  async function handleSubmit() {
    const otp1 = parseInt(otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5]);

    const user_id = merchant._id;
    const response = await dispatch(verifyOTP({ otp: otp1, user_id })).unwrap();
    setOtp(["", "", "", "", "", ""]);
    if (response.status) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("userData", JSON.stringify(merchant));
      navigate("/dashboard");
    }
  }

  return (
    <div
      className={`d-flex justify-content-center align-items-center flex-column login-${theme}`}
    >
      <div className="d-flex align-items-center justify-content-center p-3">
        {theme === "light" ? (
          <img src={logo} alt="logo" />
        ) : (
          <img src={logo1} alt="logo" />
        )}
      </div>
      <div
        className="d-flex align-items-center justify-content-center flex-column p-3 col-xl-4 col-lg-5 col-md-6 col-sm-7 col-9"
        style={{ backgroundColor: "#fff", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-center p-3">
          <img src={authenticateIcon} alt="logo" />
        </div>
        <div className="authenticate-heading pb-1">
          Authenticate Your Account
        </div>
        <div className="d-flex align-items-center authenticate-text text-center pb-4">
          Enter the authorization code sent to {email}
        </div>
        <div className="d-flex justify-content-center pb-3">
          {otp.map((digit, index) => (
            <input
              className="input-code-box"
              key={index}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
        </div>
        <button className="sign-in-btn w-100 p-1" onClick={handleSubmit}>
          <div className="sign-in-btn d-flex justify-content-center p-1">
            SIGN IN
          </div>
        </button>
        <div
          className="authenticate-text2 p-2"
          onClick={handleResend}
          style={{ cursor: "pointer" }}
        >
          Resend Code.
        </div>
      </div>
    </div>
  );
}

export default Authenticate;
