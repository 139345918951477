import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import watchReducer from "./watchSlice";
import detailWatchReducer from "./detailWatchSlice";
import addWatchReducer from "./addWatchSlice";
import deleteWatchReducer from "./deleteWatchSlice";
import updateWatchReducer from "./updateWatchDetailSlice";
import clientReducer from "./clientsSlice";
import highTransferReducer from "./highTransferSlice";
import ThemeReducer from "./themeSlice";
import menuBarReducer from "./menuBarSlice";
import verifyTokenReducer from "./verifyTokenSlice";
import statReducer from "./statSlice";
import addCertificateReducer from "./addCertificateSlice";
import twoFAReducer from "./twoFactAuth";
import verifyOTPReducer from "./verifyOTPSlice";
import resendOTPReducer from "./resendOTPSlice";
import forgetPasswordReducer from "./forgetPasswordSlice";
import resetPasswordReducer from "./updatePassword";
import updateMerchantReducer from "./updateMerchantSlice";
import clientsNameReducer from "./clientsNameSlice";
import updateClientReducer from "./updateClientSlice";
import deleteClientReducer from "./deleteClientSlice";
import detailClientReducer from "./detailClientSlice";
import GraphDataReducer from "./GraphDataSlice";
import getTransactionReducer from "./getTransactionSlice";
import GetMetadataReducer from "./metadataSlice";
import maintainanceReducer from "./maintainanceSlice"

export const store = configureStore({
  reducer: {
    login: loginReducer,
    watch: watchReducer,
    detailWatch: detailWatchReducer,
    addWatch: addWatchReducer,
    deleteWatch: deleteWatchReducer,
    updateWatch: updateWatchReducer,
    client: clientReducer,
    clientsName: clientsNameReducer,
    highTransferWatch: highTransferReducer,
    theme: ThemeReducer,
    menu: menuBarReducer,
    token: verifyTokenReducer,
    stats: statReducer,
    addCertificate: addCertificateReducer,
    twoFA: twoFAReducer,
    verifyOTP: verifyOTPReducer,
    resendOTP: resendOTPReducer,
    forgetPassword: forgetPasswordReducer,
    resetPassword: resetPasswordReducer,
    updateMerchant: updateMerchantReducer,
    updateClient: updateClientReducer,
    deleteClient: deleteClientReducer,
    detailClient: detailClientReducer,
    GraphData: GraphDataReducer,
    getTransaction: getTransactionReducer,
    getMetadata: GetMetadataReducer,
    maintainance:maintainanceReducer
  },
  // other options e.g middleware, go here
});
