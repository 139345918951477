import axios from "../axios.js";
import Swal from "sweetalert2";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const updateClientSlice = createSlice({
  name: "client",
  initialState: {
    data: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    setClient(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateClient.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setClient, setStatus } = updateClientSlice.actions;
export default updateClientSlice.reducer;

// Thunks
export const updateClient = createAsyncThunk("client/update-client", async (data) => {
  const config = {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  };


  const response = await axios
    .put(`client/update-client` ,  data.data, config)
    .catch((error) => {
      console.log("ok");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Client not updated.Something went wrong!!!",
        confirmButtonColor: "#4182EB",
      });
    });
  if (response.data.success) {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Client data updated successfully.",
      confirmButtonColor: "#4182EB",
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed, User data not updated.",
      confirmButtonColor: "#4182EB",
    });
  }
  console.log(response);
  return response.data;
});

export const addClient = createAsyncThunk("client/update", async (data) => {
  const config = {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  };
  const response = await axios.post("/client/add-client", data.data, config)
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Client already exist.Something went wrong!!!",
        confirmButtonColor: "#4182EB",
      });
    });
  if (response.data.success) {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Client added successfully.",
      confirmButtonColor: "#4182EB",
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed, Client data not added.",
      confirmButtonColor: "#4182EB",
    });
  }
  console.log(response);
  return response.data;
});